

#app.route-stores {
    #view {
        // right: 0;
        background: $body-background-color;
    }
}


.view-stores {

    $header-height: 160px;
    $footer-height: 40px;

    position: absolute;
    top: 0; left: 0; bottom: 0; right: 0;

    .loadable.loading {
        z-index: 8;
    }

    main {
        display: flex;
        flex-direction: column;
        height: 100%;
        position: relative;
        overflow: hidden;

        &.drop-mode .drag-and-drop-zone {
            height: 100%;
            width: 100%;
        }

        .drag-and-drop-zone {
            position: absolute;
            height: 0;
            width: 0;
            z-index: 6;
            opacity: 0.8;
            background: $white;
        }

        .metrics-funnel-breadcrumb-actions-panel {
            display: flex;
            background-color: $white;
            z-index: 0;

            .left-panel {
                display: flex;
                align-items: center;
                border-bottom: $border;
                border-right: $border;
            }
        }

        .actions-panel-row {
            width: 100%;
            border-top: none;
            box-shadow: 0px 2px 10px rgba(black, 0.05);
            background: $white;
            display: flex;

            .selected-filters {
                display: inline-block;
                min-width: 200px;
                flex-grow: 1;
            }

            .properties-items {
                width: 100%;
                border-bottom: $border;
                .items-list .ui-pellets .ui-pellet {
                    border-radius: 14px;
                }
            }

            &.last-row {
                position: relative;
                height: 68px;
                .actions-hide-button {
                    position: absolute;
                    right: 0;
                    bottom: 0;

                    .button-toggle-actions-panel {
                        display: flex;
                        align-items: center;
                        font-size: 11px;
                        font-weight: 400;
                        color: $light-grey;
                        padding: 0 8px;
                        background: none;
                        box-shadow: none;
                        border: none;
                        height: 100%;
                        line-height: unset;
                        height: 16px;
                        margin-bottom: 4px;

                        &:hover {
                            color: $blue;
                        }

                        span {
                            padding-right: 2px;
                        }

                        i {
                            height: 12px;
                            width: 12px;
                        }
                    }
                }
            }

            .funnel-state, .available-properties {

                header {
                    padding-top: 5px;

                    h1, button {
                        display: inline-block;
                        line-height: 15px;
                        height: 15px;
                        font-size: 10px;
                        color: $mid-grey;
                        text-transform: uppercase;
                        font-weight: 600;
                        letter-spacing: 1px;
                    }
                    h1 {
                        margin-bottom: 11px;
                    }
                    button {
                        display: inline-block;
                        font-size: 10px;
                        margin-left: 8px;
                        color: rgba($blue, 0.5);
                        text-decoration: none;
                        &:hover {
                            color: $blue;
                            text-decoration: underline;
                        }
                    }
                }
            }

            .available-properties {
                position: relative;
                padding: 0px 10px;
                background: $white;
                min-height: 70px;
            }
        }

        .store-main-body {
            height: 100%;
            width: 100%;
            display: flex;
            flex-direction: column;
            z-index: 4;

            .main-part {
                height: 100%;
                width: 100%;
                display: flex;
                z-index: 0;

                .main-body-sliding-part {
                    width: 100%;
                }

                .main-body-part {
                    height: 100%;
                    width: 100%;
                    display: flex;
                    flex-direction: column;
                    flex: 1;

                    .store-text-row {
                        border-top: 0px;
                    }
                }
            }

            .in-transition {
                transition: top 0.7s ease-in-out;
            }
        }

        .store-text-row {
            display: flex;
            flex-flow: row nowrap;
            justify-content: space-between;
            align-items: center;
            border-top: $border;

            color: $mid-grey;
            font-size: 14px;
            height: 40px;
            font-style: italic;
            background: $body-background-color;

            .toggle-actions-panel-button {
                cursor: pointer;
                display: flex;
                align-items: center;
                font-size: 11px;
                font-weight: 400;
                color: $light-grey;
                padding: 0 8px;
                height: 100%;
                line-height: unset;
                height: 16px;
                margin-bottom: 4px;

                &:hover {
                    color: $blue;
                }

                span {
                    padding-right: 2px;
                }

                i {
                    height: 12px;
                    width: 12px;
                }
            }

            .natural-language {
                display: flex;
                align-items: center;
                margin: 0 10px;
                flex-wrap: wrap;
                .grouping,
                .metric {
                    margin-left: 5px;
                    margin-right: 5px;
                }
                .order,
                .limit {
                    margin-left: 5px;
                }

                span {
                    color: $dark-grey;
                    &:not(.metric) {
                        text-transform: capitalize;
                    }
                }
            }

            .actions {
                flex: 0 1 auto;
                height: 100%;
                display: flex;
                position: relative;

                .default-actions {
                    height: 40px;
                    position: relative;
                    transition: all 0.5s ease;

                    .chart-export.disable {
                        color: $light-grey;
                        cursor: not-allowed;

                        .icon-export::before,
                        .icon-export:hover::before {
                            color: $light-grey;
                        }
                    }
                }

                &.full {
                    .default-actions {
                        margin-right: 96px;
                    }
                }

                button.reversed {
                    position: absolute;
                    right: 0;
                    top: 40px;
                    padding-right: 0;
                    margin-right: 0;

                    &.show {
                        top: 12px;
                    }

                    &:hover i {
                        color: $blue;
                    }

                    i {
                        display: flex;
                        color: #aaa;
                        flex-direction: row-reverse;
                        &::before {
                            padding-top: 1px;
                            margin-left: 4px;
                            padding-right: 0;
                        }
                    }
                }

                button {
                    display: inline-flex;
                    flex-flow: row nowrap;
                    justify-content: space-around;
                    align-items: center;
                    position: relative;
                    top: 12px;
                    float: right;
                    line-height: 1;
                    color: $dark-grey;
                    height: 30px;
                    padding: 5px 7px;
                    padding-right: 10px;
                    font-size: 11px;
                    border: $border;
                    margin: 0px 5px;
                    transition: all 0.5s ease;

                    &:first-child {
                        margin-right: 0;
                    }
                    &.line {
                        margin-right: 0px;
                        border-right: 0px;
                    }
                    &.stacked-area {
                        margin-left: 0px;
                    }

                    &:hover, &.selected {
                        background: $white;
                        color: $blue;
                        i:before {
                            color: $blue;
                        }
                    }

                    i {
                        line-height: 1;
                        width: auto;
                        flex: 1;
                        position: relative;
                        top: -0.5px;
                        &:before {
                            color: $dark-grey;
                            padding-right: 7px;
                            opacity: 0.5;
                        }
                    }
                }
            }
        }

        .store-chart-container {
            height: 100%;
            flex: 1 1 auto;
            padding: 10px;
            padding-top: 20px;
            border-radius: 0;

            .highcharts-container {
                top: 0;
                bottom: 0;
            }

            .highcharts-axis text,
            .highcharts-axis-labels text,
            .highcharts-axis-labels span {
                font-family: $font-stack !important;
                text-decoration: none !important;
                fill: $dark-grey !important;
                color: $dark-grey !important;
                text-transform: none !important;
                font-weight: 400 !important;
            }

            .highcharts-axis text {
                font-size: 14px !important;
            }

            .highcharts-axis-labels text,
            .highcharts-axis-labels span {
                font-size: 12px !important;
            }

            svg > text {
                display: none;
            }

            .store-chart {
                height: 100%;
                width: 100%;
                position: absolute;
                top: 0; left: 0; bottom: 0; right: 0;
            }
        }
    }


    footer {
        position: absolute;
        bottom: 0;
        left: 0;
        right: 0;
        height: $footer-height;

        .button-export {
            position: absolute;
            right: 0px;
            top: 0px;
            height: $footer-height;
            padding: 0 4px;
            padding-top: 10px;

            font-size: 12px;

            &:hover {
                cursor: pointer;
                color: $blue;
            }
        }
    }

}
