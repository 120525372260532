// @import url('https://fonts.googleapis.com/css?family=Ropa+Sans|Libre+Barcode+39+Text|Source+Sans+Pro');

body.fullscreen {
    #user-menu {
        display: none !important;
    }

    .route-counter {
        #subheader {
            display: none !important;
        }
        .status-message-bar {
            display: none;
        }
        & > main > *,
        & > header {
            display: none;
        }
        & > main > main {
            display: block;
        }
        #view {
            transition: all 0.2s;
            top: 0 !important;
            right: 0 !important;
            min-width: auto;
        }
        & > main {
            top: 0 !important;
            min-width: auto;
        }
    }

    // .view-counter .counter-metric {
    //     font-size: 15vmax;
    // }

    @media only screen and (max-width: 700px) {
        .view-counter .counter-metric {
            font-size: 15vw !important;
        }
        .counter-metric footer {
            font-size: 0.8em;
        }
    }
}

.view-counter {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    font-family: 'Ropa Sans';
    color: lighten($light-grey, 10%);
    background-color: rgba($navy, 0.8);
    transform: translateZ(0);

    .background-image {
        position: fixed;
        top: -10px;
        left: -10px;
        bottom: -10px;
        right: -10px;
        background-color: lighten($navy, 2%);
        background-size: cover;
        background-repeat: no-repeat;
        background-position: center center;
        background-blend-mode: multiply, darken;
        z-index: -1;
    }

    .logo {
        $size: 10vmin;
        height: $size;
        width: $size;
        position: absolute;
        left: 2vmin;
        top: 2vmin;
        background-image: url('~images/42logo-black.png');
        background-size: $size;
        background-repeat: no-repeat;
        background-position: center center;
        display: inline-block;
        filter: invert(100%);
        opacity: 1;
    }

    // .organization-logo {
    //     height: 60px;
    //     width: 380px;
    //     position: absolute;
    //     left: 120px; top: 20px;
    //     background-image: url(https://logos-download.com/wp-content/uploads/2016/05/AllSaints_logo_logotype.png);
    //     background-size:     cover;
    //     background-repeat:   no-repeat;
    //     background-position: center center;
    //     display: inline-block;
    //     filter: invert(100%);
    //     opacity: 0.2;
    // }

    .perchevron:before {
        animation: upDown 1.5s alternate infinite ease-in-out;
    }

    @keyframes upDown {
        to {
            transform: translatey(4px);
        }
    }

    .button-fullscreen {
        position: absolute;
        top: 25px;
        right: 20px;
        font-size: 10vmin;
        color: $white;
        opacity: 0.2;
        cursor: pointer;
        &:hover {
            opacity: 0.4;
        }
    }

    .counter-metric {
        font-size: 50vmin;
        display: flex;
        flex-direction: column;
        align-items: stretch;
        justify-content: center;
        width: calc(100% - 20vmin);
        height: calc(100% - 5vmin);

        h1 {
            font-size: 0.2em;
            letter-spacing: 1px;
            text-align: center;

            span {
                opacity: 0.4;
                cursor: pointer;
                position: relative;
            }
        }

        main {
            flex: 1 0 auto;
            align-self: center;
            display: flex;
            flex-direction: column;
            justify-content: center;
            text-align: center;
            width: 100%;
        }

        footer {
            flex: 0 0 auto;
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: space-between;
            width: 100%;
            font-size: 0.4em;
        }
    }

    .counter-metric-money-currency {
        font-size: 0.5em;
        opacity: 0.8;
        display: inline-block;
    }

    .counter-metric-money-value {
        display: inline-block;
    }

    .counter-metric-ty {
        font-size: 1.2em;
        letter-spacing: 1px;
        // animation: colorchange 15s infinite;
        $color: adjust-hue(#5da5da, 0);
        transform: rotateZ(360deg), translateZ(0);
        text-shadow: 0.005em 0.005em $color, 0.01em 0.01em $color, 0.015em 0.015em $color, 0.02em 0.02em $color;
    }

    @keyframes colorchange {
        0% {
            $color: adjust-hue(#5da5da, 0);
            text-shadow: 0.005em 0.005em $color, 0.01em 0.01em $color, 0.015em 0.015em $color, 0.02em 0.02em $color;
        }
        25% {
            $color: adjust-hue(#5da5da, 360 * 0.25);
            text-shadow: 0.005em 0.005em $color, 0.01em 0.01em $color, 0.015em 0.015em $color, 0.02em 0.02em $color;
        }
        50% {
            $color: adjust-hue(#5da5da, 360 * 0.5);
            text-shadow: 0.005em 0.005em $color, 0.01em 0.01em $color, 0.015em 0.015em $color, 0.02em 0.02em $color;
        }
        75% {
            $color: adjust-hue(#5da5da, 360 * 0.75);
            text-shadow: 0.005em 0.005em $color, 0.01em 0.01em $color, 0.015em 0.015em $color, 0.02em 0.02em $color;
        }
        100% {
            $color: adjust-hue(#5da5da, 0);
            text-shadow: 0.005em 0.005em $color, 0.01em 0.01em $color, 0.015em 0.015em $color, 0.02em 0.02em $color;
        }
    }

    label {
        font-size: 0.3em;
        text-shadow: 0.02em 0.02em lighten($navy, 15%);
        letter-spacing: 1px;
    }

    .counter-metric-money, .counter-metric-growth {
        font-family: 'Source Sans Pro';
    }

    .counter-metric-ly {
        letter-spacing: 1px;
        font-size: 0.7em;
        $color: #f17cb0;

        .counter-metric-money {
            text-shadow: 0.02em 0.02em lighten($navy, 15%);
        }
    }

    .counter-metric-growth {
        label {
            text-align: right;
        }
        letter-spacing: 1px;
        font-size: 0.7em;
        text-shadow: 0.02em 0.02em lighten($navy, 15%);
    }
}
