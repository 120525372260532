.tooltip-container {
    display: flex;
    flex-direction: column;

    .triangle {
        width: 0;
        height: 0;
        border-left: 5px solid transparent;
        border-right: 5px solid transparent;
        border-bottom: 5px solid black;
        margin-left: 10px;
    }

    .messages {
        pointer-events: none;
        user-select: none;
        background-color: black;
        opacity: 0.8;
        border-radius: 0.2em;
        color: $white;
        padding: 0.1em 0.7em;
        line-height: 2em;
        font-weight: 400;
        font-size: 12px;
        text-transform: none;

        display: flex;
        flex-direction: column;
    }
}
