
.customer-stats {
    width: 100%;
    margin-bottom: 20px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    min-height: 96px;

    li {
        flex: 1 1 auto;
        text-align: center;
        margin: 5px;
        margin-left: 0px;
        padding: 5px;

        &:not(:last-of-type) {
            border-right: $border;
        }

        .value {
            font-weight: 300;
            font-size: 24px;
            line-height: 1.4;
            display: block;
        }

        .title {
            font-size: 11px;
            display: block;
            color: $mid-grey;
            line-height: 1.4;
            text-transform: uppercase;
            font-weight: 400;
        }

        .avg {
            font-size: 11px;
            margin-top: 4px;
            line-height: 1.1;
            font-weight: 400;
            color: $mid-grey;
            em {
                color: $dark-grey;
                font-weight: 600;
            }
            small {
                text-transform: lowercase;
                margin-left: 0.3em;
            }
        }
    }
}
