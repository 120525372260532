.view-marketing {
    height: 100%;
}

.marketing-page-container {
    position: relative;
    padding: 20px;
    display: flex;
    flex-direction: column;
}

.marketing-page-stats-container,
.marketing-page-grid-container {
    display: flex;
    flex-direction: column;
    padding-top: 0;
}
.marketing-page-stats-container,
.marketing-page-grid-container,
.marketing-page-chart-container {
    border-color: darken($border-color, 15%)
}

.marketing-page-stats-container {
    margin-bottom: 26px;
    padding-top: 0;
    padding-bottom: 14px;
    border-radius: 10px;
    overflow: hidden;
}

.marketing-page-grid-container {
    margin-bottom: 26px;
    padding-bottom: 24px;
    border-radius: 10px;
    overflow: hidden;

    >:not(:first-child) {
        margin-top: 16px;
    }
}

.marketing-page-chart-container {
    margin-bottom: 26px;
    padding-bottom: 24px;
    padding-top: 0;
    border-radius: 10px;
    overflow: hidden;

    >:not(:first-child) {
        margin-top: 16px;
    }
}

.marketing-page-stats-block,
.marketing-page-grid-block,
.marketing-page-chart-block {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.marketing-page-chart-block,
.marketing-page-grid-block {
    padding: 0 24px;
}

.view-marketing .separator {
    width: 95%;
    height: 1px;
    background-color: $border-color;
    margin: 10px 0;
}

.marketing-platform-title {
    font-size: 16px;
    line-height: 1.3;
    text-transform: uppercase;
    letter-spacing: 1px;
    font-weight: 600;
    margin-top: 10px;
    width: 130px;
    display: flex;
    justify-content: flex-start;
    flex-shrink: 0;
}

.marketing-card-header-container {
    width: 100%;
    height: 60px;
    display: flex;
    align-items: center;
    padding: 10px;
    border-bottom: $border;
    background: $body-background-color;

    &.no-icon {
        padding-left: 36px;
    }

    .title-list-container {
        display: flex;
        text-transform: capitalize;
        letter-spacing: 1px;
        font-size: 16px;
    }
}

.message-container {
    user-select: none;
    height: 160px;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    background: $white;
    font-size: 14px;
    text-align: center;
    span {
        color: $red;
        border-radius: 15px;
        padding: 5px 12px;
        background: rgba($light-red, 0.1);
    }
    i {
        color: $light-red;
        font-size: 42px;
        margin-bottom: 20px;
        display: block;
        opacity: 0.8;
    }
}

.marketing-stats,
.marketing-grid-container,
.marketing-chart-container {
    &:not(.error) .message-container {
        display: none;
    }

    &.error .message-container {
        opacity: 0.9;
    }
}

.marketing-stats {
    position: relative;
    padding: 10px 0;
    overflow: hidden;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;

    .marketing-platform-title {
        padding-left: 24px;
    }

    .marketing-stats-list {
        width: 100%;
    }

    &.loading ul {
        opacity: 0;
    }

    &:not(.loading) ul {
        opacity: 1;
    }

    ul {
        display: grid;
        grid-gap: 0px;
        max-width: 100%;
        transition: opacity 0.3s;
    }

    &.small {
        font-size: 14px;

        .marketing-platform-title {
            font-size: 14px;
        }

        ul {
            grid-template-columns: repeat(auto-fit, minmax(100px, 1fr));
        }
        ul li:nth-child(4n+1) {
            border-left: none !important;
        }
    }

    &.medium {
        font-size: 16px;

        .marketing-platform-title {
            font-size: 15px;
        }

        ul {
            grid-template-columns: repeat(auto-fit, minmax(110px, 1fr));
        }
        ul li:nth-child(6n+1) {
            border-left: none !important;
        }
    }

    &.large {
        font-size: 20px;
        ul {
            // grid-template-columns: repeat(8, minmax(100px, 1fr));
            grid-template-columns: repeat(auto-fit, minmax(135px, 1fr));
        }
        ul li:nth-child(8n+1) {
            border-left: none !important;
        }
    }

    &:not(.error) .message-container {
        display: none;
    }

    &.error .message-container {
        opacity: 0.9;
    }

    .message-container {
        // margin: 20px;
        position: absolute;
        top: 0;
        left: 0;
        bottom: 0;
        right: 0;
        z-index: $z-elevated;
    }

    li {
        text-align: center;
        border-left: $border;
        padding: 10px 10px;
        position: relative;

        &.selected {
            .title {
                color: $light-blue;
            }
        }

        &:hover {
            cursor: default;
        }

        .title {
            color: $mid-grey;
            text-transform: uppercase;
            max-width: 200px;
            display: inline-block;
            font-size: 0.5em;
            color: $mid-grey;
            line-height: 1.3;
            text-transform: uppercase;
            font-weight: 400;
            margin-bottom: 7px;
        }

        .value {
            font-weight: 300;
            font-size: 1.3em;
            margin-bottom: 9px;

            &.blank {
                position: relative;
                color: $light-grey !important;
            }
        }

        .growth {
            font-size: 0.5em;
            margin-bottom: 0px;
            margin-left: 8px;
            min-height: 20px;

            .perchevron {
                position: relative;
                left: -0.45em;
            }

            .growth-stat {
                border-radius: 6px;
            }
        }
    }
}

.marketing-chart-container {
    min-height: 335px;
    width: 100%;

    &.echarts-item-single {
        margin-bottom: 0;
    }

    .echarts-main {
        padding: 0;
    }

    .marketing-chart-header {
        height: 30px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-bottom: 30px;

        .marketing-grid-platform-title {
            font-size: 16px;
            line-height: 1.3;
            text-transform: uppercase;
            letter-spacing: 1px;
            font-weight: 600;
        }

        .marketing-chart-selections {
            display: flex;
            justify-content: flex-end;
            height: 26px;
            align-items: center;
        }
    }

    .empty-selection {
        display: flex;
        height: 160px;
        width: 100%;
        justify-content: center;
        align-items: center;
        text-transform: uppercase;
        font-size: 12px;
        letter-spacing: 1px;
        font-weight: 500;
    }
}

.multi-check-dropdown {
    position: relative;
    width: 200px;
    margin-left: 10px;
    cursor: pointer;

    .multi-check-dropdown-header {
        height: 26px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        border: $border;
        border-radius: 4px;
        padding: 0 8px;

        &.opened {
            .icon-right-open-mini {
                transform: rotate(-90deg);
            }
        }

        .icon-right-open-mini {
            transform: rotate(90deg);
        }

        .multi-check-dropdown-header-title {
            cursor: pointer;
        }
    }

    .multi-check-dropdown-options {
        position: absolute;
        z-index: 4;
        top: 25px;
        width: 100%;
        display: flex;
        flex-direction: column;
        border: $border;
        border-radius: 0 0 4px 4px;
        overflow: hidden;
        background-color: $white;

        .multi-check-dropdown-item {
            height: 30px;
            display: flex;
            width: 100%;
            background-color: $white;

            &.selected {
                background-color: rgba(41, 153, 255, 0.1);

                .icon-check,
                .multi-check-dropdown-item-title {
                    color: $blue;
                }
            }

            &:hover {
                background-color: rgba(41, 153, 255, 0.1);
            }

            .icon-check {
                color: transparent;
            }

            .multi-check-dropdown-check {
                height: 100%;
                width: 20px;
                display: flex;
                justify-content: center;
                align-items: center;
            }

            .multi-check-dropdown-item-title {
                height: 100%;
                width: 100%;
                display: flex;
                align-items: center;
                font-size: 12px;
            }
        }
    }
}

.marketing-grid-container {
    display: flex;
    align-self: stretch;
    margin-bottom: 16px;
    align-items: center;
    flex-direction: column;
    min-height: 260px;

    .marketing-grid-header {
        margin-bottom: 16px;
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: space-between;

         .more-info-label {
             display: flex;
             align-items: center;
             justify-content: flex-end;
             height: 14px;
             a {
                 border-bottom: 1px dotted #777;
                 color: #555;
                 font-weight: 600;
                 font-size: 12px;
                 line-height: 1;
             }
         }

        .marketing-grid-platform-title {
            font-size: 16px;
            line-height: 1.3;
            text-transform: uppercase;
            letter-spacing: 1px;
            font-weight: 600;
        }
     }

    &.small {
        font-size: 14px;

        .marketing-platform-title {
            font-size: 14px;
            width: 100px;
        }
    }

    &.medium {
        .marketing-platform-title {
            font-size: 15px;
        }
    }

    .grid-container {
        $grid-header-height: 70px;
        $grid-footer-height: 40px;

        width: 100%;
        left: 0; right: 0; bottom: 0; top: 0;
        border: $border;
    }
}
