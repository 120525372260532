.view-sales,
.view-overview {
    position: relative;
    height: 100%;

    &.loading {
        pointer-events: none;
        overflow: hidden;
        & > .loadable.loading {
            position: absolute;
            top: 0; left: 0; bottom: 0; right: 0;
            background: $body-background-color;
            z-index: $z-elevated;
            &::before {
                display: none;
            }
        }
    }

    .view-sales-container {
        margin: 20px;
        overflow-x: hidden;
    }

    .message-container {
        user-select: none;
        position: absolute;
        height: 100%;
        top: 0;
        left: 0;
        bottom: 0;
        right: 0;
        z-index: $z-elevated;
        display: flex;
        align-items: center;
        justify-content: center;
        background: $white;
        font-size: 14px;
        text-align: center;
        .message {
            display: flex;
            flex-direction: column;
        }
        span {
            color: $red;
            border-radius: 15px;
            background: rgba($light-red, 0.1);
        }
        i {
            color: $light-red;
            font-size: 42px;
            margin-bottom: 20px;
            display: block;
            opacity: 0.8;
        }
    }

    .overall-stats {
        position: relative;
        margin-bottom: 20px;
        padding: 10px 0;
        padding-bottom: 20px;
        overflow: hidden;
        width: 100%;

        &.loading ul {
            opacity: 0;
            visibility: hidden;
        }

        &:not(.loading) ul {
            opacity: 1;
        }

        &:not(.error) .message-container {
            display: none;
        }

        &.error .message-container {
            opacity: 0.9;
        }

        ul {
            display: grid;
            grid-gap: 0px;
            max-width: 100%;
            transition: opacity 0.3s;
        }

        &.initializing ul {
            visibility: hidden;
        }

        &.small {
            font-size: 22px;
            ul {
                grid-template-columns: repeat(2, minmax(100px, 1fr));
            }
            ul li:nth-child(2n+1) {
                border-left: none !important;
            }
        }

        &.medium {
            font-size: 23px;
            ul {
                grid-template-columns: repeat(3, minmax(100px, 1fr));
            }
            ul li:nth-child(3n+1) {
                border-left: none !important;
            }
        }

        &.large {
            font-size: 24px;
            ul {
                grid-template-columns: repeat(4, minmax(100px, 1fr));
            }
            ul li:nth-child(4n+1) {
                border-left: none !important;
            }
        }

        li {
            text-align: center;
            border-left: $border;
            padding: 10px 10px;
            position: relative;

            &.selected {
                .title {
                    color: $light-blue;
                }
            }

            &:hover {
                cursor: zoom-in;

                .value, .growth, .perchevron {
                    color: $blue !important;
                }

                .title {
                    color: $light-blue;
                }
            }

            .title {
                color: $mid-grey;
                text-transform: uppercase;
                max-width: 200px;
                display: inline-block;
                font-size: 0.5em;
                color: $mid-grey;
                line-height: 1.3;
                text-transform: uppercase;
                font-weight: 400;
                margin-bottom: 7px;
            }

            .value {
                font-weight: 300;
                font-size: 1.3em;
                margin-bottom: 9px;

                &.blank {
                    position: relative;
                    color: $light-grey !important;
                }
            }

            .growth {
                font-size: 0.5em;
                display: flex;
                justify-content: center;
                min-height: 20px;

                .growth-stat {
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    padding: 4px;
                    border-radius: 4px;

                    .growth-stat-description {
                        margin-right: 0.2em;
                    }
                }
            }
        }
    }

    .overview-chart {
        &.empty main {
            min-height: 275px;
        }

        &.error:not(.loading) main .message-container {
            padding-bottom: 54px;
        }
    }

    .card.echart-with-query .message-container{
        top: 50px;
        height: calc(100% - 50px)
    }

    .card-title {
        .metric {
            text-align: center;
            font-weight: 600;
            font-style: normal;
        }
        .metric + .metric::before {
            font-weight: 400;
            text-decoration: none !important;
            content: ', ';
        }
    }

    .top-items {
        width: 100%;
        margin-bottom: 20px;
        padding: 15px;
        min-height: 325px;

        &.hidden {
            display: none;
        }

        > .top-items-header {
            height: 16px;
            width: 100%;
            display: flex;
            padding: 0 8px;
            margin-bottom: 10px;
            justify-content: space-between;

            .header-label,
            .more-info-label {
                height: 100%;
                width: 50%;
                display: flex;
                align-items: center;
            }

            .more-info-label {
                justify-content: flex-end;
                user-select: none;
            }

            a {
                height: 14px;
                border-bottom: 1px dotted $mid-grey;
                color: $dark-grey;
                font-weight: 400;
                font-size: 10px;
                line-height: 1;
                font-style: italic;

                &:hover {
                    color: $blue;
                }
            }
        }

        > .items {
            padding-top: 10px;
            width: 100%;
            display: flex;

            > ul {
                display: flex;
                flex-direction: row;
                align-items: baseline;
                justify-content: center;
                width: 100%;

                &.collapsed {
                    flex-direction: column;
                    align-items: stretch;

                    .item-container {
                        align-self: stretch;
                        max-width: initial;
                        &:not(:last-of-type) {
                            margin-bottom: 30px;
                        }
                    }
                    .item-name {
                        font-size: 16px;
                    }
                    .item {
                        padding: 30px 15px;
                        border-right: none;
                    }

                    .item-grid-cell-value  {
                        padding: 30px 15px 13px 15px;
                        border: 1px solid darken($border-color, 5%);
                        border-radius: 4px;
                    }
                }

                &:not(.collapsed) .item-container:not(:last-of-type) {
                    border-right: $border;
                }
            }

            .item-container {
                background-color: $white;
                flex: 1 auto;
                cursor: default;
                position: relative;
                display: block;

                .top-item-view-item {
                    overflow: hidden;
                    border-radius: 2px;
                    border-left: 4px solid $white;
                    border-right: 4px solid $white;
                }
            }
        }
    }
}
