@forward "ui/pellets";
@forward "ui/funnel-state";
@forward "ui/buttons";
@forward "ui/tabs";

.ui {
    user-select: none;
}

.ui-select2 {
    user-select: none;
    $height: 30px;
    font-size: 11px;
    display: inline-block;
    height: $height;
    overflow: hidden;
    color: $dark-grey;

    &:hover,
    &.active {
        .faceplate {
            border-color: $border-color;
            i {
                opacity: 0.8;
            }
        }
    }

    &.active {
        box-shadow: 0px 1px 3px rgba(black, 0.1);
        .faceplate {
            border-radius: 3px 3px 0px 0px;
        }
        .popup {
            position: absolute;
            border-radius: 0px 0px 3px 3px;
            pointer-events: auto;
        }
    }

    .faceplate {
        display: flex;
        flex-flow: row nowrap;
        justify-content: space-around;
        align-items: center;
        cursor: pointer;
        border: 1px solid rgba($border-color, 0.8);
        padding: 5px 10px;
        background: $white;
        height: 100%;
        border-radius: 3px;

        .selected {
            flex: 1;
        }

        i {
            flex: 0;
            display: block;
            margin: 0 -4px;
            font-size: 16px;
            opacity: 0.6;
            &:before {
                margin: 0;
            }
        }
    }

    .popup {
        border: $border;
        border-top: none;
        z-index: $z-elevated;
        background: $white;
        box-shadow: 0px 1px 3px rgba(black, 0.1);
        max-height: 300px;
        overflow: auto;
        overflow-y: scroll;
        overscroll-behavior: contain;
        pointer-events: none;

        li {
            cursor: pointer;

            & > * {
                padding: 15px 10px;
                padding-right: 50px;
            }

            &:not(:last-child) > * {
                border-bottom: $border;
            }

            &.selected,
            &:hover {
                color: $blue;
            }
        }
    }
}

#overlay {
    transition: background-color 0.5s;
    transform: translateZ(0);
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: none;

    &.overlay-enabled {
        background-color: rgba(black, 0.7);
    }
}

.map {
    background-color: $light-blue;
}

.card {
    position: relative;
    border: 1px solid darken($border-color, 5%);
    // box-shadow: 0px 2px 0px 0px $border-color;
    border-radius: 6px;
    background: $white;

    .card-title {
        font-size: 12px;
        font-weight: 400;
        font-family: $font-stack;
    }

    .chart-options-container {
        display: flex;
        align-items: center;
        flex: 0 0 auto;

        .separator {
            height: 50%;
            width: 2px;
            margin-right: 4px;
            margin-left: 8px;
            background: lighten($light-grey, 20%);
        }

        .chart-expanded-theme {
            height: 26px;
            width: 34px;
            cursor: pointer;
            font-size: 12px;
            flex: 1 0 auto;
            display: flex;
            align-items: center;
            justify-content: center;
            border: 0.5px solid transparent;
            border-radius: 6px;
            margin-left: 4px;
            user-select: none;

            &.active {
                color: $blue !important;
                background: rgba($blue, 0.1);
            }

            &:not(.active):hover {
                color: $blue;
                background: rgba($border-color, 0.5);
            }
        }
    }
}

.card,
.supertable,
.loadable,
.button-export {
    position: relative;

    &:before,
    &:after {
        position: absolute;
        top: 0;
        left: 0;
        bottom: 0;
        right: 0;
        content: '';
        opacity: 0;
        pointer-events: none;
    }

    h1,
    .loadable-title {
        position: relative;
        z-index: $z-elevated;
    }

    &.empty:not(.loading) {
        pointer-events: none;

        &:first-line {
            font-weight: 600;
        }

        &:after {
            display: flex;
            justify-content: center;
            flex-direction: column;
            text-align: center;

            text-align: center;
            content: 'No data found for the selected filter(s) and time range!\a Try expanding the time range or changing your filters.';
            white-space: pre;
            line-height: 1.6em;
            background: $white;
            color: $dark-grey;
            font-size: 14px;
            z-index: $z-elevated;
            opacity: 1;
        }
    }

    &.loading {
        pointer-events: none;

        h1,
        .loadable-title {
            opacity: 0.5;
        }

        &::before {
            z-index: $z-elevated;
            background: white;
            transition: opacity 0.2s;
            opacity: 1;
        }

        &::after {
            transform: scale(1, 1);
            opacity: 0.9;
            z-index: $z-elevated;
            background: transparent url('~images/spinner.gif') no-repeat;
            background-position: center center;
            background-size: 50px;
            transition: opacity 0.2s cubic-bezier(0.19, 1, 0.22, 1);
        }
    }
}

.card.loading {
    &::before {
        border-radius: 6px;
    }
    &::after {
        border-radius: 6px;
    }
}

.percent-positive {
    color: $green !important;
}

.percent-negative {
    color: $red !important;
}

.percent-invert-color.percent-positive {
    color: $red !important;
}

.percent-invert-color.percent-negative {
    color: $green !important;
}

.chart-tooltip .percent-positive {
    color: lighten($green, 10%) !important;
}

.chart-tooltip .percent-negative {
    color: lighten($red, 25%) !important;
}

.growth-stat {
    background-color: transparent;
    padding: 4px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.growth-stat.percent-positive {
    color: $green !important;
    background-color: $green-background-color;
}

.growth-stat.percent-invert-color.percent-negative {
    color: $green !important;
    background-color: $green-background-color !important;
}

.growth-stat.percent-negative {
    color: $red !important;
    background-color: $red-background-color !important;
}

.growth-stat.percent-invert-color.percent-positive {
    color: $red !important;
    background-color: $red-background-color !important;
}

.loadable {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    pointer-events: none;
}

.sortable {
    position: relative;
    user-select: none;
    cursor: pointer;

    &:after {
        transition: transform 0.1s;
        float: right;
        width: 0px;
        content: '\e86e';
    }

    &:hover {
        cursor: pointer;
    }

    &[sort-order='ascending']:after {
        font-size: 12px;
        content: '\e886'; // arrow up
    }

    &[sort-order='descending']:after {
        font-size: 12px;
        content: '\e883'; // arrow down
    }

    &:not(.sortable-before):after {
        font-family: $icon-stack;
        color: rgba($mid-grey, 0.5);
        font-size: 12px;
        position: relative;
        top: 1px;
    }

    &:hover:after {
        color: $mid-grey;
    }

    &[sort-order='ascending'],
    &[sort-order='descending'] {
        color: rgba($blue, 0.8) !important;
        &:after {
            color: rgba($blue, 0.6) !important;
        }
    }
}

$toggle-width: 40px;

// do generic dropdown layout changes here
.dropdown {
    user-select: none;

    .dropdown-toggle:after {
        text-align: center;
        display: block;
        width: $toggle-width;
    }

    .dropdown-menu {
        position: absolute;
        transition: all 200ms cubic-bezier(0.24, 0.22, 0.015, 1), opacity 100ms linear;
        transform: translate(0, 5px);
        opacity: 0;
        visibility: hidden;
        width: 100%;
        height: auto;
    }

    &.open {
        z-index: 12;

        & > .dropdown-menu {
            visibility: visible;
            opacity: 1;
            z-index: 9;
            transform: translate(0, 0);
        }
    }
}

.dropdown {
    backface-visibility: hidden;
    $row-height: 40px;

    .dropdown-menu {
        position: absolute;
        top: 100%;
        left: 0;
        display: flex;
        flex-direction: column;
        background: $navy;
        color: $white;
        font-size: 10px;
        line-height: $row-height;
        font-weight: 800;
        letter-spacing: 1px;
        margin-top: 3px;
        text-transform: uppercase;
        border-radius: 3px;
        backface-visibility: hidden;
        // width: 100%;
        max-height: 300px;
        overflow-y: scroll;
        overflow-x: hidden;
        box-shadow:0px 2px 5px 1px rgba(0,0,0,0.25);

        &::-webkit-scrollbar {
            height: 100%;
            width: 10px;
            background: none;
        }

        &::-webkit-scrollbar-track,
        &::-webkit-scrollbar-track-piece {
            background: $light-navy;
            border-left: 1px solid lighten($navy, 10%);
        }

        &::-webkit-scrollbar-thumb {
            background: lighten($navy, 15%);
            &:hover {
                background: lighten($navy, 20%);
            }
        }

        li {
            width: 100%;
            padding: 0 10px;

            i {
                display: none;
            }

            &:hover {
                cursor: pointer;
                color: $yellow;
                background: lighten($navy, 10%);
            }

            &.delete {
                color: $light-red;

                &:hover {
                    color: $white;
                    background: $red;
                }
            }

            &.selected {
                color: $yellow !important;
            }
        }
    }
}


%echarts-item {
    position: relative;

    header {
        padding: 20px;
        padding-bottom: 0px;
    }

    .blank {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        display: flex;
        align-items: center;
        justify-content: center;
        font-style: italic;
        font-size: 13px;
    }
    .echarts-title {
        display: block;
        margin-left: 20px;
        margin-top: 20px;
    }
    .echarts-container {
        min-height: 275px;
    }
    .echarts-main {
        padding: 0px 20px;
        padding-bottom: 10px;
        height: 275px;

        .tooltip {
            padding: 2px 3px;
            font-size: 10px;
            line-height: 1.2;

            &.columns {
                display: flex;
            }

            .tooltip-column:not(:last-of-type) {
                margin-right: 12px;
                padding-right: 12px;
                border-right: 1px solid $dark-grey;
            }

            h1 {
                font-weight: bold;
                text-transform: uppercase;
                letter-spacing: 1px;
                font-size: 8px;
                margin-bottom: 4px;

                &.tooltip-header {
                    color: $white;
                }
            }

            .tooltip-series:not(:last-child) {
                margin-bottom: 8px;
            }

            .tooltip-series {
                display: flex;
                flex-direction: row;
                justify-content: space-between;
                font-size: 10px;

                .tooltip-series-label {
                    color: $white;
                    &.negative {
                        color: $light-red;
                    }
                }
            }
            .tooltip-series-label + .tooltip-series-value {
                margin-left: 5px;
            }
            .tooltip-series-value {
                color: $white;
                margin-left: 0px;
                &.negative {
                    color: $light-red;
                }
            }
        }
    }

    .bucket-picker {
        height: 50px;
        font-size: 12px;
        line-height: 50px;

        ul {
            padding: 0;

            li {
                width: 70px;
                display: inline-block;
                line-height: 24px;
                text-align: center;
                cursor: pointer;
                text-transform: uppercase;
                letter-spacing: 1px;
                font-size: 10px;
                color: rgba($mid-grey, 0.7);
                border: 1px solid white;
            }
        }
    }
}

.echarts-item-single {
    @extend %echarts-item;
    margin-bottom: 20px;
    user-select: none;

    & > header {
        display: flex;
        justify-content: space-between;
        padding: 0 15px;
        padding-top: 4px;
        position: relative;
        z-index: $z-elevated;
    }

    & > main {
        position: relative;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
    }
}

.echarts-grid {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(350px, 4fr));
    grid-gap: 10px;
    margin-top: 20px;
    margin-bottom: 20px;

    .echarts-item {
        @extend %echarts-item;

        header {
            display: flex;
            justify-content: space-between;
            padding: 0px;
        }

        .echarts-title {
            height: 30px;
            flex: 1 1 auto;
        }

        .chart-picker {
            height: 50px;
            font-size: 12px;
            line-height: 50px;
            margin-right: 10px;
            flex: 0 0 auto;

            ul {
                padding: 0;

                li {
                    width: 34px;
                    display: inline-block;
                    line-height: 24px;
                    text-align: center;
                    cursor: pointer;
                    text-transform: uppercase;
                    letter-spacing: 1px;
                    font-size: 12px;
                    color: rgba($mid-grey, 0.7);
                    border: 0.5px solid transparent;
                }
            }
        }
    }
}

.bucket-picker, .chart-picker {
    ul > li {
        border-width: 1px !important;
        border-radius: 6px;
        margin-left: 4px;
        &.active {
            color: $blue !important;
            // border-color: $border-color !important;
            background: rgba($blue, 0.1);
        }

        &:not(.active):hover {
            color: $blue;
            background: rgba($border-color, 0.5);
        }
    }
}
