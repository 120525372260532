@import '~styles/utils/variables';
@import '~styles/base';

.actions-panel {
    max-height: 68px;
    width: 100%;
    padding-left: 18px;
    padding-right: 4px;
    position: relative;
    display: flex;
    border-bottom: $border;
    background-color: $white;

    .main-actions-panel {
        max-height: 56px;
        display: flex;
        align-items: flex-end;
        margin-top: 10px;
        margin-bottom: 10px;

        >:not(:last-child) {
            margin-right: 20px;
        }
    }

    .actions-panel-item {
        height: 100%;
        margin-right: 8px;
        display: flex;
        align-items: flex-end;
        user-select: none;
        position: relative;

        .button-action-item .button-action-item-selected-icon {
            margin-right: 4px;
        }

        .select-action-item {
            min-width: 100px;
            position: relative;
            background: $white;

            & > * {
                position: relative;
                z-index: 2;
            }

            &::before {
                position: absolute;
                left: 0;
                right: 0;
                bottom: 0;
                top: 0;
                content: '';
                z-index: 1;
                background: white;
                pointer-events: none;
            }

            .select-action-item-header {
                display: flex;
                justify-content: space-between;
                align-items: center;
                position: relative;
                left: 5px;
            }

            .button-action-item-label {
                height: 24px;
                display: flex;
                align-items: center;
                color: $mid-grey;
                text-transform: uppercase;
                letter-spacing: 1px;
                font-size: 9px;
                pointer-events: none;
                font-weight: 600;
            }
            .button-action-item-selected {
                border: $border;
                padding-left: 6px;
                font-size: 10px;
                height: 30px;
                display: flex;
                justify-content: space-between;
                align-items: center;
                cursor: pointer;

                i {
                    color: $light-grey;
                    height: 12px;
                    width: 12px;
                    transition: transform 0.3s ease-in-out;
                }

                .reversed {
                    padding-right: 6px;
                }

                &:not(.reversed) i {
                    margin-top: 2px;
                    margin-left: 4px;
                }

                &:hover {
                    color: $mid-grey;
                    background-color: darken($lighter-grey, 2%);
                }

                i {
                    font-size: 14px;
                }
            }

            &.active:not(.active-only-icon) {
                .button-action-item-label {
                    color: $blue;
                }

                .button-action-item-selected {
                    border-color: $blue;
                    color: $blue;
                    i {
                        color: $blue;
                    }
                }
            }

            .toggle-text {
                height: 16px;
                font-size: 8px;
                display: flex;
                cursor: pointer;
                z-index: 4;

                .toggle-left-text,
                .toggle-right-text {
                    padding: 0 6px;
                    display: flex;
                    align-items: center;
                    border-radius: 4px;
                }

                .toggle-label {
                    color: darken($border-color, 25%);
                    letter-spacing: 0.5px;
                    text-transform: uppercase;
                    font-weight: 600;

                    &.selected {
                        color: $blue;
                        background: rgba($blue, 0.1);
                        cursor: default;
                    }

                    &:not(&.selected):hover {
                        color: darken($blue, 10%);
                    }
                }
            }

            .button-action-item-selected {
                position: relative;
                font-size: 12px;
                padding-left: 6px;
                padding-right: 3px;
                display: flex;
                justify-content: space-between;
                align-items: center;
                z-index: 3;
                cursor: pointer;
                user-select: none;
                border-radius: 6px;
                background: $lighter-grey;
                box-shadow:
                    inset 0px 0px 0px 1px $white,
                    0px 1.2px 1px 0.4px rgba(black, 0.06);
                text-shadow: 0px 1px white;
                border: 1px solid darken($border-color, 5%);
                border-bottom-width: 1.5px;
                color: $dark-grey;

                &.select {
                    pointer-events: none;
                }

                &.reversed {
                    padding-right: 6px;
                }

                .button-action-item-selected-label {
                    display: -webkit-box;
                    -webkit-box-orient: vertical;
                    -webkit-line-clamp: 2;
                    padding-left: 4px;
                    padding-right: 10px;
                }

                i {
                    display: flex;
                    align-items: center;
                    margin-right: 6px;
                    color: darken($border-color, 20%);
                }

                &:hover, &.hovered {
                    color: $mid-grey;
                    background-color: darken($lighter-grey, 2%);
                }
            }

            & > select {
                z-index: 0;
                height: 30px;
                position: absolute;
                left: 0;
                right: 0;
                bottom: 0;
                width: 100%;
                border: none;
                border-radius: 3px;
                font-size: 14px;
            }
        }

        .ui-metric-selector-tree-container {
            display: flex;
            height: 100%;
            align-items: flex-end;

            .ui-metric-selector {
                right: unset;
                left: 0px;
                min-height: 450px;
            }

            button.metric-expand {
                height: 30px;
                width: 112px;
                top: 0px;
                margin-right: 0px;
                display: flex;
                justify-content: center;
                align-items: center;

                i {
                    margin-left: 0px;
                    top: 0px;
                }

                span {
                    width: 76px;
                }
            }
        }
    }

    .secondary-actions-panel {
        position: absolute;
        right: 0;
        bottom: 4px;
        display: flex;
        align-items: flex-end;

        >:not(:last-child) {
            margin-right: 6px;
        }

        .secondary-button-action-item {
            position: relative;
            overflow: hidden;
            height: 30px;
            min-width: 74px;
            display: flex;
            align-items: center;
            justify-content: space-between;
            padding-right: 8px;
            padding-left: 10px;
            cursor: pointer;
            margin-bottom: 4px;

            border-radius: 6px;
            background: #f9f9fa;
            box-shadow: inset 0px 0px 0px 1px #fff, 0px 1.2px 1px 0.4px rgba(0, 0, 0, 0.06);
            text-shadow: 0px 1px white;
            border: 1px solid #d6dde5;
            border-bottom-width: 1.5px;
            color: $mid-grey;
            text-transform: uppercase;
            letter-spacing: 1px;
            font-size: 9px;
            font-weight: 600;

            i {
                color: #a8b6c7;
            }

            &.hide {
                display: none;
            }

            &:hover {
                cursor: pointer;
                i {
                    color: $mid-grey;
                }
                background-color: #f3f3f5;
            }

            &.active {
                border-color: $blue;
                color: $blue;

                i {
                    color: $blue;
                }
            }

            i, i:before {
                margin-left: 4px;
                margin-right: 0px;
            }

            .loadable.loading::after {
                transform: scale(0.5, 0.5);
            }
        }

        .secondary-simple-action-item {
            display: flex;
            align-items: center;
            font-size: 11px;
            font-weight: 400;
            color: $light-grey;
            padding: 0 8px;
            border: none;
            height: 16px;
            margin-bottom: 4px;
            cursor: pointer;

            &.hide {
                display: none;
            }

            &:not(.reversed) i {
                margin-left: 4px;
            }

            &.reverse {
                flex-direction: row-reverse;

                i {
                    margin-right: 4px;
                }
            }

            &:hover {
                color: $blue;
            }
        }
    }
}
