@use "../utils/variables" as *;


.ui-pellet {
    overflow: hidden;
    display: inline-flex;
    align-items: center;
    justify-content: stretch;
    font-size: 11px;
    color: $dark-grey;
    border-radius: 14px;
    padding: 6px 10px;
    margin: 4px 2px;
    border: 1px solid darken($border-color, 5%);
    background: $lighter-grey;
    user-select: text;

    .ui-pellet-detail,
    .ui-pellet-property {
        font-style: italic;
        letter-spacing: 0.2px;
        font-size: 10px;
        opacity: 0.9;
        padding-right: 0;
        flex: 0 0 fit-content;
        font-weight: 400;
    }

    & > span:not(:last-child)::after {
        content: '';
        border-right: 1px solid darken($border-color, 6%);
        position: relative;
        margin: 0 6px;
    }

    .ui-pellet-value {
        position: relative;
        font-size: 11px;
        padding-left: 0;
        flex: 0 0 fit-content;
        user-select: text;

        &:not(:first-child) {
            font-weight: 600;
        }
    }

    &.active:not(.disabled) {
        color: $blue;
        background: rgba($blue, 0.02);
        border-color: lighten($blue, 20%);

        &.ui-pellet-property::after {
            border-color: lighten($blue, 10%);
        }
    }

    &.active.disabled {
        background: $white;
        border-radius: 8px;
    }

    &:not(.disabled) {
        box-shadow: 0 1px 1px 0 rgba(black, 0.05);
    }
}

.ui-pellet:not(.disabled):is(:hover, .selected) {
    opacity: 1.0;
    cursor: pointer;
    color: $white;
    background: lighten($blue, 6%);
    border-color: $blue;

    .ui-pellet-property::after {
        border-color: $white;
        opacity: 0.3;
    }
}

.ui-pellet.available-property {
    user-select: none;
}

.funnel-node-selected ~ .funnel-node {
    .ui-pellet:not(:hover) {
        color: rgba($mid-grey, 0.7);
        border-style: dashed;
        border-color: transparent;
        box-shadow: none;
    }
}

.funnel-node {
    user-select: none;
    .ui-pellet {
        margin-left: 0;
    }
}

.funnel-state .help-text {
    .ui-pellet {
        position: relative;
        top: 1px;
        font-style: normal;
        margin: 0 3px;
        font-size: 11.5px;
        padding: 5px 8px;
        span {
            position: relative;
            top: -1px;
        }
    }
}

.ui-pellets {
    padding-bottom: 8px;
}
