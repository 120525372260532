@use "../utils/variables" as *;


.ui-button {
    height: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;

    padding: 12px 8px;
    border-radius: 6px;
    border: 1px solid darken($border-color, 5%);
    background: lighten($border-color, 10%);
    box-shadow: 0 1px 0 rgba(black, 0.02);
    color: $dark-grey;
    text-align: center;
    font-weight: 600;
    font-size: 10px;
    text-transform: uppercase;
    letter-spacing: 1px;
    cursor: pointer;

    span, i {
        display: block;
        align-self: center;
    }

    span {
        position: relative;
        top: -0.5px;
        flex: 1 1 auto;
        white-space: nowrap;
    }

    i {
        color: rgba(darken($border-color, 20%), 1);
        opacity: 1;
        position: absolute;
        flex: 0 0 auto;
        font-size: 12px;
        margin-left: -2px;

        &:after {
            margin: 0;
        }
    }

    &:hover {
        background-color: darken($lighter-grey, 0.5%);
    }

    &:focus {
        box-shadow: none;
    }

    &:active {
        box-shadow: inset 0 1px 1px rgba($navy, 0.1);
    }

    &.active {
        background-color: darken($lighter-grey, 0.5%);
        box-shadow: inset 0 1px 1px rgba($navy, 0.1);
    }
}


.ui-button-export, .button-export {
    &.disabled {
        cursor: not-allowed;
        color: darken($lighter-grey, 15%);
        &:hover {
            .tooltip-container {
                display: flex;
            }
        }
    }
    font-size: 12px;
    min-width: 60px;
    text-decoration: underline;
    font-weight: 600;
    color: $mid-grey;

    &:not(.disabled):hover {
        color: $blue;
        cursor: pointer;
    }

    &.loading {
        &::before {
            display: none;
        }
        &::after {
            background-size: 25px !important;
        }
    }

    i::before {
        position: relative;
        top: 1px;
        padding-right: 3px;
    }
    &.loading i {
        transition-delay: 0.0s;
        opacity: 0;
    }
    &:not(.loading) i {
        transition: opacity 0.2s cubic-bezier(0.19, 1, 0.22, 1);
        transition-delay: 0.1s;
        opacity: 1;
    }

    .tooltip-container {
        position: absolute;
        top: 30px;
        left: 0;
        z-index: 4;
        display: none;
    }
}


button.button-yellow {
    background: $yellow;
    color: $darker-grey;
    border: rgba($navy, 0.8);
    &:hover {
        background: $light-yellow !important;
    }
}
