@use 'sass:math';

$smart-group-width: 280px;
$smart-group-height: 48px;
$smart-group-filter-height: 45px;
$smart-group-info-width: 260px;
$smart-group-actions-width: 200px;
$smart-group-panel-background: $white;

.smart-groups-panel {
    $smart-group-actions-height: 50px;
    $side-padding: 15px;
    $circle: 14px;
    font-size: 14px;
    user-select: none;

    &.drop-mode .drag-and-drop-zone {
        height: 100%;
        width: 100%;
    }

    .drag-and-drop-zone {
        position: absolute;
        height: 0;
        width: 0;
        z-index: 6;
        opacity: 0.8;
        background: $white;
    }

    .smart-group-actions {
        background: $white;
        background: $lighter-grey;
        box-shadow: inset 0px 0px 0px 1px rgba(white, 0.8);
        position: absolute;
        left: 0;
        right: 0;
        top: 0;
        height: $smart-group-actions-height;
        // box-shadow: inset 0px -1px rgba(black, 0.01);
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 8px 6px;
        border-bottom: $border;

        .smart-group-actions-label {
            color: $mid-grey;
            font-size: 14px;
            display: none;
        }

        .smart-group-action-create {
            max-height: $smart-group-actions-height * calc(2/3);
            width: 100%;
            margin: 12px 0px;
            border-top-right-radius: 0px;
            border-bottom-right-radius: 0px;
        }

        .smart-group-action-import {
            max-height: $smart-group-actions-height * calc(2/3);
            width: 30px;
            margin: 12px 0px;
            border-top-left-radius: 0px;
            border-bottom-left-radius: 0px;
            border-left: transparent;

            position: relative;

            .create-tab-dropdown-container {
                position: absolute;
                width: 90px;
                right: -1px;
                top: 34px;
                transition: all 200ms cubic-bezier(0.24, 0.22, 0.015, 1), opacity 100ms linear;
                transform: translate(0, 5px);
                opacity: 0;
                visibility: hidden;
                overflow: hidden;
                border-radius: 4px;
                box-shadow:0px 2px 5px 1px rgba(0,0,0,0.25)
            }

            .create-tab-dropdown-item {
                height: 30px;
                padding: 0 10px;
                display: flex;
                align-items: center;
                background: $navy;
                color: #fff;
                letter-spacing: 1px;
                font-size: 9px;
                font-weight: 700;

                &:first-child {
                    border-radius: 5px 0px 0px 0px;
                }

                &:last-child {
                    border-radius: 0px 0px 5px 5px;
                }

                &:hover {
                    cursor: pointer;
                    color: $yellow;
                    background: lighten($navy, 10%);
                }
            }

            &.active .create-tab-dropdown-container {
                visibility: visible;
                opacity: 1;
                z-index: 9;
                transform: translate(0, 0);

                .create-tab-dropdown-item {
                    line-height: 35px;
                    font-weight: 400;
                    letter-spacing:1.75px;
                }

                :not(:first-child) {
                    border-top: 0.5px solid rgba(255,255,255,0.075);
                }
            }
        }
    }

    .smart-groups {
        user-select: none;
        position: absolute;
        top: $smart-group-actions-height;
        bottom: 0;
        left: 0;
        right: 0;
        background: $white;
        overflow-y: auto;
        overflow-x: visible;
    }

    .smart-group-container {
        &.placeholder {
            opacity: 0;
        }
    }

    .smart-group {

        &.hidden {
            overflow: hidden;
        }
        position: relative;
        // overflow: hidden;
        background-color: $white;
        transition: height 0.33s, opacity 0.01s;

        &:not(.selected)::after {
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            box-shadow: inset 0px -1px 0px 0px $border-color;
            content: '';
            pointer-events: none;
            z-index: $z-elevated;
        }

        &:not(.selected):not(.remove) {
            height: $smart-group-height;
        }

        &:not(.selected) {
            overflow: hidden;
        }

        &.new {
            height: 0px !important;
        }

        &:not(.remove).selected {
            height: $smart-group-height + ($smart-group-filter-height * 2);
        }

        &.remove {
            height: 0px !important;
            opacity: 0;
            transition: height 0.33s, opacity 42s;
        }

        &:hover {
            cursor: pointer;
        }

        .smart-group-edit-items-container {
            border-radius: 4px 4px 4px 0px;
        }

        .button-smart-group-edit {
            position: absolute;
            border: none;
            bottom: 0;
            right: 0;
            top: 0;
            z-index: 6;
            color: rgba(darken($border-color, 20%), 1);
            background: none;
            cursor: pointer;
            display: flex;
            flex-direction: column;
            align-items: flex-end;

            .smart-group-edit-button {
                height: 48px;
                width: 48px;
                display: flex;
                justify-content: center;
                align-items: center;
                border-radius: 5px 5px 0px 0px;

                & > i {
                    height: 48px;
                    width: 48px;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                }

                &:not(.opened) > i:hover:before {
                    border-radius: 50%;
                    background: $border-color;
                    border: 0.5em solid $border-color;
                }

                &.opened {
                    background: $navy;
                    color: $light-grey;
                    & > i:hover:before {
                        border-radius: 50%;
                        background: lighten($navy, 10%);
                        border: 0.5em solid lighten($navy, 10%);
                    }
                }
            }

            .smart-group-edit-item {
                height: 30px;
                width: 100px;
                padding-left: 10px;
                display: flex;
                align-items: center;
                background: $navy;
                color: #fff;
                letter-spacing: 1px;
                font-size: 9px;
                font-weight: 700;

                &:first-child {
                    border-radius: 5px 0px 0px 0px;
                }

                &:last-child {
                    border-radius: 0px 0px 5px 5px;
                }

                &:hover {
                    cursor: pointer;
                    color: $yellow;
                    background: lighten($navy, 10%);
                }

                &.delete {
                    color: $light-red;

                    &:hover {
                        color: $white;
                        background-color: $red;
                    }
                }
            }
        }

        .smart-group-info-normal {
            position: absolute;
            top: 0;
            left: 0;
            bottom: 0;
            right: 0;
        }

        .smart-group-info {
            position: relative;
            box-shadow: 0px 2px 0px 0px rgba(black, 0.02);
            // z-index: $z-elevated;
        }

        &.selected {
            cursor: default;
            .smart-group-name {
                transition: color 0.3s;
                color: $blue;
                cursor: text;
                i:before {
                    color: rgba($blue, 0.6);
                    transform: rotate(90deg);
                }
            }
            &:not(.new)::after {
                position: absolute;
                top: 0;
                left: 0;
                right: 0;
                bottom: 0;
                border: 1px solid $blue;
                box-shadow: 0px 0px 5px rgba(rgb(23, 26, 35), 0.2);
                border-radius: 5px;
                content: ' ';
                pointer-events: none;
                z-index: $z-elevated;
                opacity: 0.8;
            }
        }

        &:not(.selected):hover {
            .smart-group-name {
                color: $darker-grey;
            }
        }

        .smart-group-filters {
            transition: opacity 0.01s;
        }

        &:not(.selected) {
            .smart-group-filters {
                opacity: 0;
                transition: opacity 42s;
            }
        }

        &.selected {
            .smart-group-filters {
                opacity: 1;
            }
        }
    }

    .smart-group-edit-actions {
        position: absolute;
        top: $smart-group-height + 1px;
        left: 2px;
        right: 2px;
        height: $smart-group-height;
        display: flex;
        background: $white;
        padding: 5px;
        border: $border;
        border-top: none;
        z-index: $z-base;
        border-radius: 0px 0px 3px 3px;
        box-shadow: 0px 1px rgba(black, 0.05);

        button {
            height: $smart-group-height - 10px;
            line-height: $smart-group-height - 10px - 4px !important;
        }
        .smart-group-edit-save-button {
            width: calc(65% - 5px);
            border-radius: 5px;
        }
        .smart-group-edit-cancel-button {
            margin-left: 5px;
            width: 35%;
            border-radius: 5px;
        }
    }

    .smart-group-info {
        height: $smart-group-height;
        left: 0;
        right: 0;
        bottom: 0;
        top: 0;
        background-color: $white;
        position: relative;

        input[type='text'] {
            position: absolute;
            top: 1px;
            bottom: -1px;
            left: 3px;
            right: 3px;
            width: calc(100% - 4px);
            color: $dark-grey;
            font-size: 14px;
            font-weight: 600;
            letter-spacing: 0;
            line-height: math.div($smart-group-height, 2);
            border: $border;
            display: block;
            padding: 10px 12px;
            transform: translate(-1px, -1px);
        }

        .smart-group-name {
            position: absolute;
            top: 0;
            bottom: 0;
            left: 2.5px;
            right: $smart-group-height;
            display: inline-block;
            color: $dark-grey;
            font-size: 14px;
            font-weight: 600;
            line-height: $smart-group-height;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;

            i:before {
                position: relative;
                top: 2px;
                font-size: 20px;
                color: $mid-grey;
                transition: transform 0.1s;
            }
        }
    }

    .smart-group-filters {
        bottom: 0;
        top: 0;
        width: 100%;
        background: $lighter-grey;

        .smart-group-filter {
            display: inline-block;
            height: $smart-group-filter-height;
            display: flex;
            align-items: center;
            padding: 0 20px;
            position: relative;
            width: 100%;
            font-size: 11px;
            font-weight: 500;
            letter-spacing: 1px;
            text-transform: uppercase;
            background: $lighter-grey;
            border-top: 1px solid lighten($border-color, 2%);
            overflow: hidden;

            span {
                display: -webkit-box;
                -webkit-line-clamp: 2;
                -webkit-box-orient: vertical;
                line-height: 16px;
                overflow: hidden;
                text-overflow: ellipsis;
            }

            &:last-child {
                box-shadow: inset 0px -2px 0px 0px rgba(black, 0.02);
            }

            p {
                position: absolute;
                font-size: 15px;
                color: $mid-grey;
                line-height: $smart-group-filter-height;
            }

            &:hover {
                cursor: pointer;
                background: $body-background-color;
            }

            &.active {
                background: rgba($blue, 0.8);
                color: $white;
            }

            &.selected:not(.active) {
                position: relative;
                color: $blue;
                background: $body-background-color;
            }
        }
    }
}
