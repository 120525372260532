@use "../utils/variables" as *;


.ui-tabs-with-menu {
    $height: 40px;
    height: $height;
    min-height: $height;
    display: flex;
    flex-direction: row;
    background: white;
    width: 100%;
    border-bottom: 1px solid $border-color;

    .ui-tabs-actions {
        flex: 0 0 auto;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-left: 8px;
        // margin-right: 8px;
        &::after {
            padding-left: 6px;
            content: '';
            border-right: $border;
            height: 50%;
            box-shadow: 5px 0px 10px $white;
        }
        z-index: 9;
    }

    .ui-tabs-action-add-tab {
        height: 20px;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 5px;
        margin-right: 4px;
        user-select: none;

        cursor: pointer;
        font-size: 9px;
        background: rgba($border-color, 0.1);

        box-shadow:
            inset 0px 0px 0px 1px $white,
            0px 1.2px 1px 0.4px rgba(black, 0.06);

        &:hover {
            background: rgba($border-color, 0.5);
        }
        &:active {
            background: rgba($border-color, 0.8);
        }
        & > span {
            font-weight: normal;
            flex: 0 0 auto;
            top: 0px;
            color: darken($border-color, 40%);
        }
        & > i {
            position: relative;
            margin-right: 3px;
        }
        i::before {
            margin-left: 0px;
            margin-right: 3px;
        }
    }

    .ui-tabs-container {
        height: 100%;
        flex: 1 1 auto;
        overflow: hidden;
        display: flex;
        flex-direction: row;
    }

    .ui-tabs {
        height: 100%;
        flex: 1 1 auto;
    }

    .new-import-button {
        height: 25px;
        display: flex;
        margin-right: 2px;

        .create-tab-dropdown-container {
            position: absolute;
            right: -1px;
            top: 26px;
            transition: all 200ms cubic-bezier(0.24, 0.22, 0.015, 1), opacity 100ms linear;
            transform: translate(0, 5px);
            opacity: 0;
            visibility: hidden;
            overflow: hidden;
            border-radius: 4px;
            box-shadow:0px 2px 5px 1px rgba(0,0,0,0.25)
        }

        .right-side.active {
            .create-tab-dropdown-container {
                visibility: visible;
                opacity: 1;
                z-index: 9;
                transform: translate(0, 0);

                .create-tab-dropdown-item {
                    line-height: 35px;
                    font-weight: 400;
                    letter-spacing:1.75px;
                }

                :not(:first-child) {
                    border-top: 0.5px solid rgba(255,255,255,0.075);
                }
            }
        }

        .create-tab-dropdown-item {
            height: 30px;
            padding: 0 10px;
            display: flex;
            align-items: center;
            background: $navy;
            color: #fff;
            letter-spacing: 1px;
            font-size: 9px;
            font-weight: 700;

            &:first-child {
                border-radius: 5px 0px 0px 0px;
            }

            &:last-child {
                border-radius: 0px 0px 5px 5px;
            }

            &:hover {
                cursor: pointer;
                color: $yellow;
                background: lighten($navy, 10%);
            }
        }

        .new-import-button-style {
            height: 100%;
            display: flex;
            justify-content: space-between;
            border: 1px solid darken($border-color, 5%);
            color: $dark-grey;
            text-align: center;
            font-weight: 600;
            font-size: 10px;
            text-transform: uppercase;
            letter-spacing: 1px;
            background: rgba(230, 234, 239, 0.1);
            box-shadow: inset 0px 0px 0px 1px #fff, 0px 1.2px 1px 0.4px rgba(0, 0, 0, 0.06);
            cursor: pointer;

            span, i {
                display: block;
                align-self: center;
            }

            span {
                font-size: 9px;
                width: 100%;
                justify-content: center;
                align-items: center;
                white-space: nowrap;
                font-weight: normal;
                color: #6a829f;
            }

            i {
                color: rgba(darken($border-color, 20%), 1);
                opacity: 1;
                width: 14px;
                display: flex;
                justify-content: center;
                align-items: center;
                font-size: 12px;
            }

            &:hover {
                background: rgba(230, 234, 239, 0.5);
            }

            &:focus {
                box-shadow: none;
            }

            &:active {
                background: rgba(230, 234, 239, 0.5);
                box-shadow: inset 0 1px 1px rgba($navy, 0.1);
            }
        }

        .left-side {
            width: 58px;
            border-radius: 6px 0 0 6px;
            padding: 0px 4px;

            &.single {
                border-radius: 6px;
            }
        }

        .right-side {
            width: 20px;
            border-radius: 0 6px 6px 0;
            border-left: transparent;
            position: relative;

            &.active {
                background: rgba(230, 234, 239, 0.5);
                box-shadow: inset 0 1px 1px rgba($navy, 0.1);
                user-select: none;
            }

            i {
                width: 100%;
            }
        }
    }

    .ui-tabs-actions-scroll {
        position: relative;
        flex: 0 0 auto;
        display: flex;
        flex-direction: row;
        z-index: 3;

        .ui-tab-button {
            background: rgba($border-color, 0.1);
            box-shadow:
            inset 0px 0px 0px 2px $white,
                0px 0px 2px 0px rgba(black, 0.05);
            &:first-child {
                border-radius: 5px 0px 0px 5px;
            }
            &:hover {
                background: rgba($border-color, 0.3);
            }
            &:active {
                background: rgba($border-color, 0.8);
            }
            height: $height;
            width: $height;
            border: none;
            border-left: $border;
            padding: 0px;
            margin: 0px;
            display: block;
            i {
                color: darken($border-color, 30%);
                font-size: 18px;
                height: inherit;
                width: inherit;
                display: flex;
                align-items: center;
                justify-content: center;
            }
            i::before {
                top: 0;
                margin-left: 0px;
            }
        }
    }

    ul.dropdown-menu {
        max-width: 300px;
        width: 100%;
        height: inherit;
        cursor: pointer;
        overflow-y: visible;
    }
}

.ui-tabs {
    width: 100%;
    height: 100%;
    position: relative;
    user-select: none;
    overflow-y: hidden;
    overflow-x: auto;
    position: relative;
    padding-left: 6px;

    scrollbar-width: none;
    &::-webkit-scrollbar {
        display: none;
    }

    & > ul {
        height: 100%;
        white-space: nowrap;
        display: inline-flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
    }

    .ui-tab-placeholder {
        opacity: 0;
    }

    .ui-tab-container {
        position: relative;
        display: inline-flex;
        flex-direction: column;
        height: 100%;
        margin-right: 4px;

        &::before {
            position: absolute;
            border-radius: 5px;
            $padding: 6px;
            top: $padding; bottom: $padding; left: 0; right: 0;
            content: '';
        }
        &::after {
            display: none;
            content: '';
            position: absolute;
            top: 0; left: 0; right: 0; bottom: 0;
        }
        &.selected::after {
            display: block;
            border-bottom: 2px solid $blue;
        }

        &:not(.selected) {
            cursor: pointer;
            &:hover::before {
                background-color: darken($lighter-grey, 1%);
            }
            &:hover .ui-tab .icon-drag-container:not(:hover) {
                svg {
                    fill: rgba(darken($border-color, 30%), 1);
                }
            }
        }

        &.selected {
            color: $blue;
            .ui-tab .icon-drag-container {
                svg {
                    fill: rgba(darken($border-color, 30%), 1);
                }
            }
        }

        .ui-tab {
            position: relative;
            z-index: 1;
            flex: 1 1 100%;
            padding: 0px 4px;
            display: flex;
            align-items: center;
            font-size: 12px;
            min-width: 100px;

            .tab-inner-content {
                flex: 1 1 auto;
                font-weight: 600;
                white-space: nowrap;
                margin: 0px 4px;
            }

            div.tab-inner-content {
                display: flex;
                justify-content: center;
            }

            input.tab-inner-content {
                color: $blue;
                text-align: center;
                font-size: 12px;
                box-shadow: 0px 0px 0px 1.5px rgba($blue, 0.2);
                border-radius: 4px;
                padding: 0px;
            }

            .icon-options-menu {
                $size: 18px;
                flex: 0 0 $size;
                display: flex;
                align-items: center;
                justify-content: center;
                border-radius: 2.5px;
                margin-left: 4px;
                margin-right: 2px;
                cursor: pointer;

                i {
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    font-size: 16px;
                    width: $size;
                    height: $size;
                    margin: 0px;
                    color: rgba(darken($border-color, 20%), 1);
                    &:before {
                        margin: 0px;
                    }
                }

                &:hover {
                    i { color: $navy; }
                    background-color: darken($lighter-grey, 5%);
                }
                &.toggled {
                    background-color: rgba($blue, 0.1);
                    i { color: $blue; }
                }
            }

            .icon-drag-container {
                cursor: grab;
                display: flex;
                align-items: center;
                justify-content: center;
                padding: 2px 0px;
                border-radius: 2.5px;
                margin-right: 2px;
                &:active {
                    cursor: grabbing;
                }
                &:hover {
                    background-color: darken($lighter-grey, 5%);
                    svg {
                        fill: $navy;
                    }
                }
                svg {
                    height: 16px;
                    width: 16px;
                    fill: transparent;
                }
            }
        }
    }
}
