$input-search-bar-height: 24px;
$sidebar-header-height: 37px;

.dimensions-sidebar {
    position: relative;
    flex: 0 1 auto;
    width: 308px;
    display: flex;
    flex-direction: row;
    align-items: stretch;
    justify-content: stretch;
    $resizer-width: 4px;
    min-height: 0;
    overflow: hidden;

    &.light {
        background-color: $white;
    }

    &.closed {
        display: none !important;
    }

    .dimensions-sidebar-bar {
        width: 36px;
        display: flex;
        flex-direction: column;
        flex: 1 0 auto;
    }

    .dimensions-sidebar-content {
        position: relative;
        display: flex;
        min-width: 0;
        flex: 1;
    }

    .sidebar-resizer-bar {
        position: absolute;
        top: 0; bottom: 0;
        right: 0;
        position: relative;
        border: $border;
        width: $resizer-width;
        background-color: darken($body-background-color, 2%);
        z-index: 99;
        &::after {
            transition: opacity 0s;
            position: absolute;
            top: 0;
            bottom: 0;
            left: -1px;
            right: -1px;
            content: '';
            pointer-events: none;
            background-color: $blue;
            opacity: 0;
        }
        &:hover, &.active {
            cursor: ew-resize;
            &::after {
                box-shadow: 0px 0px 10px rgba($blue, 0.1);
                opacity: 0.7;
            }
        }
    }

    .sidebar-header {
        display: flex;
        align-items: center;
        border-bottom: $border;
        padding-left: 12px;
        padding-right: 12px;
        flex: 0 0 $sidebar-header-height;

        .sidebar-header-tabs {
            display: flex;

            .sidebar-header-tab {
                user-select: none;
                padding: 0 10px;
                height: 30px;
                display: flex;
                align-items: center;
                color: $mid-grey;
                margin-right: 10px;
                border-top: 2px solid transparent;
                border-bottom: 2px solid transparent;

                &.active {
                    color: $blue;
                    // background-color: $white;
                    border-bottom-color: $blue;
                }

                &:not(.active) {
                    border-radius: 4px;
                }

                // &:not(.active):hover {
                //     background-color: #f6f6f8;
                // }
            }
        }

        .sidebar-header-title {
            font-size: 10px;
            text-transform: uppercase;
            font-weight: 600;
            letter-spacing: 1px;
        }
    }

    .sidebar-properties-menu {
        position: absolute;
        top: 0; left: 0; bottom: 0; right: 0;
        display: flex;
        flex-direction: column;
        flex: 1 1 auto;
        align-items: stretch;
        justify-content: stretch;

        .list-header-top {
            display: flex;
            height: 42px;

            .list-menu-search-box {
                width: 100%;
            }
        }

        .action-button {
            height: 42px;
            width: 42px;
            border-right: $border;
            background: white;
            box-shadow: inset 0px 0px 0px 2px $white, 0px 0px 2px 0px rgba(black, 0.05);
            border-bottom: 1px solid #e6eaef;
            display: flex;
            align-items: center;
            justify-content: center;

            i {
                color: darken($border-color, 30%);
                font-size: 18px;
                display: flex;
                align-items: center;
                justify-content: center;
            }

            &:hover {
                cursor: pointer;
                i {
                    color: $dark-grey;
                }
            }
        }

        .filter-input-search {
            flex: 1 0 42px;
            display: flex;
            flex-direction: row;
            background-color: $white;
            flex-flow: row-reverse nowrap;
            justify-content: space-around;
            align-items: stretch;
            border-bottom: $border;
            padding: 2px 6px;

            input {
                font-size: 12px;
            }

            &.filtered {
                .icon-clear-filter {
                    display: flex;
                }
                .icon-search {
                    color: $blue;
                }
                input {
                    color: $blue;
                }
            }

            &:hover .icon-search,
            input:focus ~ .icon-search {
                opacity: 0.8;
            }

            &:hover input,
            input:focus {
                @include placeholder {
                    color: rgba($dark-grey, 0.8);
                }
            }

            i {
                display: inline-block;
                text-align: center;
                flex: 0;
                color: $mid-grey;
                opacity: 0.5;
                font-size: 14px;
            }

            .icon-search {
                padding-right: 6px;
                font-size: 16px;
                display: flex;
                align-items: center;
            }

            .icon-clear-filter {
                display: none;
                padding-left: 6px;
                cursor: pointer;
                font-size: 14px;
                margin-right: -4px;
                align-items: center;
                &:hover {
                    opacity: 0.8;
                }
            }

            input {
                flex: 1;
                display: block;
                border: none;
                padding: 0;
                height: auto;
            }
        }

        .metrics-funnel-breadcrumb {
            flex: 1 1 auto;
            min-height: 70px;
            overflow-y: scroll;
            position: relative;
            padding-bottom: 50px;
            background: $body-background-color;

            header {
                .header-group-by {
                    display: flex;
                    justify-content: space-between;
                    align-items: center;
                    margin-bottom: 8px;

                    .toggle-sidebar {
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        color: rgba(darken($border-color, 20%), 1);

                        &:hover {
                            cursor: pointer;
                            color: $dark-grey;
                        }
                    }
                }

                padding-top: 5px;
                display: flex;
                flex-direction: column;
                margin-bottom: 4px;

                h1 {
                    margin-bottom: 8px;
                    font-weight: 700;
                }

                h1, button {
                    display: inline-block;
                    line-height: 15px;
                    height: 15px;
                    font-size: 12px;
                    color: $mid-grey;
                    text-transform: uppercase;
                    font-weight: 600;
                    letter-spacing: 1px;
                }

                button {
                    display: inline-block;
                    font-size: 10px;
                    margin-left: 8px;
                    color: rgba($blue, 0.5);
                    text-decoration: none;
                    &:hover {
                        color: $blue;
                        text-decoration: underline;
                    }
                }

                .hint {
                    padding-left: 5px;
                    color: $mid-grey;
                    font-size: 11px;
                    margin-bottom: 8px;
                }
            }

            .property-group {
                position: relative;
                border: $border;
                border-radius: 5px;
                background: $white;
                min-height: 32px;
                margin: 8px;

                &.opened .property-group-header {
                    border-radius: 4px;
                }

                &:not(.opened) {
                    .property-group-open-close {
                        transform: rotate(-90deg);
                    }
                    .ui-pellets {
                        position: relative;
                        display: none;
                        overflow: hidden;
                    }
                }

                .property-group-header {
                    user-select: none;
                    color: $mid-grey;
                    display: flex;
                    align-items: center;
                    cursor: pointer;
                    height: 32px;
                    z-index: 2;
                    padding: 8px 10px;
                    padding-left: 6px;
                    padding-right: 6px;
                    border-radius: 5px;
                    background: $white;

                    .property-group-header-title {
                        font-size: 9px;
                        text-transform: uppercase;
                        font-weight: 600;
                        letter-spacing: 1px;
                    }

                    .property-group-open-close {
                        font-size: 12px;
                        transition: transform .1s;
                        margin-right: 4px;
                    }

                    &:hover {
                        color: $blue;
                    }

                    transition: box-shadow 0.1s;
                    &:not(.is-stuck) {
                        box-shadow: 0px 0px 0px rgba(0,0,0,0.05);
                    }
                    &.is-stuck {
                        border-bottom: $border;
                        box-shadow: 0 2px 3px rgba(0,0,0,0.05);
                    }
                }
            }

            .selection-pebble:not(.selected) {
                background-color: #f3f3f5;
            }

            .ui-pellets {
                position: relative;
                z-index: 1;
                padding: 8px 10px;
                display: flex;
                flex-direction: column;

                .selection-pebble {
                    border-radius: 4px;
                    min-height: 28px;
                    height: unset;
                    border: none;
                    box-shadow: none;
                    margin-right: 0;
                    padding-top: 7px;
                    padding-bottom: 7px;

                    &:hover {
                        transform: none;
                    }

                    .pebble-label {
                        line-height: 14px;
                        font-style: normal;
                        font-weight: 600;
                    }
                }
                .ui-pellet {
                    line-height: 1.4em;
                    padding: 5px 10px;
                    border-radius: 16px;
                }
            }
        }
    }

    .sidebar-metrics-menu {
        flex: 1 0 auto;
        display: flex;
        flex-direction: column;
        background: $white;
        justify-content: stretch;

        > main {
            position: relative;
            flex: 1 0 auto;

            .metric-selector-container {
                position: absolute;
                top: 0; left: 0; bottom: 0; right: 0;
                overflow: scroll;
                padding-bottom: 50px;
            }

            .jstree-node {
                font-size: 12px;
            }

            .jstree-leaf {
                font-size: 12px;
            }

            .jstree-search {
                color: $darker-grey;
            }
        }
    }
}

.sidebar-header-close {
    display: none !important;
    cursor: pointer;
    width: 36px;
    height: 42px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: 2px;
    color: $dark-grey;
    font-weight: 600;

    &.closed .sidebar-toggle-icon {
        background-color: $white;
        i {
            color: $dark-grey;
            transform: rotate( -180deg );
        }
    }

    .sidebar-toggle-icon {
        padding: 8px 2px;
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 4px;
        background-color: #f6f6f8;

        i {
            color: $blue;
        }
    }

    &:hover .sidebar-toggle-icon {
        color: $blue;
        background-color: #f6f6f8;
    }
}
