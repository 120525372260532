.store-actions-panel {
    border-bottom: $border;
    background: $white;
    border-left: $border;
    border-right: $border;
    user-select: none;

    .sidebar-properties-menu .metrics-funnel-breadcrumb {
        height: 100%;
        header {
            h1 {
                display: inline-block;
            }
            .hint {
                padding-left: 5px;
                color: $mid-grey;
                font-size: 11px;
            }
        }
    }

    .label {
        font-size: 9px;
        position: absolute;
        top: 8px; left: 0;
        color: $mid-grey;
        text-transform: uppercase;
        letter-spacing: 1px;
        z-index: $z-elevated + 2;
        pointer-events: none;
        font-weight: 600;
        padding-left: 2px;
    }

    .group-by-select {
        position: absolute;
        bottom: 0; left: 10px; top: 0;

        ul:before {
            content: ' ';
            display: block;
            height: 24px;
            width: 100%;
        }

        li {
            font-size: 10px;
            color: $dark-grey;
            padding: 0 8px;
            border-radius: 10px;
            display: inline-block;
            height: 24px;
            line-height: 22px;
            border: $border;
            margin-right: 10px;
            margin-bottom: 10px;

            &:not(.selected) {
                background: $lighter-grey;
            }

            &.selected, &:hover {
                cursor: pointer;
                opacity: 1.0;
                color: $white;
                background: $blue;
                border-color: $blue;
            }
        }
    }
}
