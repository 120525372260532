@use 'sass:math';

$img-height: 135px;
$img-scaling: $img-height * math.sqrt(2);
$img-height-large: 200px;
$font-size-metric-default: 8.5px;
$font-size-metric-large: 9px;
$item-image-padding-top: 10px;
$item-grid-cell-bgcolor: white;
$item-grid-cell-padding-bottom: 30px;
$item-grid-cell-properties-bgcolor: lighten($border-color, 5%);
$item-grid-cell-properties-height: 2 * 34px;

.view-sales .item-grid-cell-value .item-image,
.view-sales .item-grid-cell-total .item-image {
    > img {
        max-height: $img-height;
    }
}

.view-sales .item-grid-cell-value.item-cell-large .item-image,
.view-sales .item-grid-cell-total.item-cell-large .item-image {
    > img {
        max-height: $img-height-large;
    }
}

.view-items .ag-row {
    background-color: $body-background-color;
    border-bottom: 1px solid $border-color;
}
.view-items .ag-cell {
    border-top: none;
    border-bottom: none;
    border-color: $border;
    border: 0.75px solid darken($border-color, 1%) !important;
    border-width: 0.75px !important;
    border-bottom-width: 0.75px !important;
    &.ag-cell-focus {
        border-width: 0.75px !important;
        border-bottom-width: 0.75px !important;
    }
}

.item-grid-cell {
    &.empty {
        display: none;
    }
    &.item-cell-large .item-image img {
        height: $img-height-large;
    }

    .item-image img {
        height: $img-height;
    }
    .item-image ~ .item-properties {
        border-top: $border;
    }
}



.item-grid-cell-total {
    .item-image {
        display: none;
    }
    &:has(.item-image:not(.hide)) {
        .item-properties {
            $height: $item-grid-cell-properties-height + $img-height;
            height: $height;
        }
    }
    &.item-cell-large:has(.item-image:not(.hide)) {
        .item-properties {
            $height: $item-grid-cell-properties-height + $img-height-large;
            height: $height;
        }
    }
    &:has(.item-image.hide) .item-properties {
        $height: $item-grid-cell-properties-height;
        height: $height;
    }
    .item-properties .item-property:first-child {
        font-size: 14px;
    }
}

.item-grid-cell-value {
    &:has(.item-image:not(.hide)) {
        .item-info {
            box-shadow:
                0px -5px 0px 6px $item-grid-cell-properties-bgcolor,
                0px -6px 0px 6px $border-color;
        }
        .item-properties {
            $height: $item-grid-cell-properties-height;
            height: $height;
            flex: 0 $height;
        }
    }
    &:has(.item-image.hide) .item-properties {
        $height: $item-grid-cell-properties-height;
        height: $height;
    }
}

.item-grid-cell {
    align-self: stretch;
    flex: 1 auto;
    display: flex;
    flex-direction: column;
    justify-content: stretch;
    align-items: space-between;
    max-width: 100%;
    position: relative;
    z-index: 0;
    background-color: $item-grid-cell-bgcolor;

    &.item-cell-large {
        .item-image {
            > img, &:has(img.blank), &:has(img:not(.loaded)) {
                $height: $img-height-large - $item-image-padding-top;
                max-height: $height;
            }
        }
    }

    .item-image {
        align-self: stretch;
        $item-image-height: $img-height - $item-image-padding-top;
        z-index: 4;
        position: relative;
        width: fit-content;
        align-self: center;
        justify-content: center;
        user-select: none;
        min-width: 0;
        margin-top: $item-image-padding-top;

        &.hide {
            display: none;
        }

        > img, &:has(img.blank), &:has(img:not(.loaded)) {
            overflow: hidden;
            max-height: $item-image-height;
            border: 2px solid rgba(white, 0.1);
            box-shadow: 0px 0px 0px 1px rgba(black, 0.1);
            border-radius: 6px;
            background: white;
        }

        &:has(img:not(.loaded):not(.blank)) {
            min-width: 90px;
            > img {
                opacity: 0;
            }
            &:before {
                background: white;
                content: "";
                position: absolute;
                top: 0; left: 0; bottom: 0; right: 0;
            }
            &::after {
                position: absolute;
                top: 0; left: 0; bottom: 0; right: 0;
                z-index: 1;
                opacity: 1;
                content: "";
                background: lighten($border-color, 6%) url('~images/spinner.gif') no-repeat;
                background-position: center center;
                background-size: 24px;
            }
        }

        &:has(img.blank) {
            > img {
                visibility: hidden;
                min-width: 120px;
            }
            background-image: url('~images/42logo-background-300.png');
            background-repeat: no-repeat;
            background-size: 120px 121px;
        }

        > img {
            align-self: stretch;
            flex: 1 auto;
            opacity: 0;
            z-index: 2;
            transition: opacity 0.1s;
            position: relative;
            object-fit: cover;
            width: auto;
            min-height: 0;
            max-width: 100%;
            display: block;
            background-color: white;
            &.loaded {
                opacity: 1;
            }
        }
    }

    .item-info {
        flex: 1 auto;
        display: flex;
        flex-direction: column;
        align-items: stretch;
        justify-content: stretch;
        overflow: hidden;
        min-width: 0;
        min-height: 0;
    }

    .item-properties {
        z-index: 2;
        align-self: stretch;
        flex: 1 auto;
        position: relative;
        background: $item-grid-cell-properties-bgcolor;
        font-size: 12px;
        padding: 5px 12px;
        display: flex;
        flex-direction: column;
        align-items: stretch;
        justify-content: space-evenly;
        overflow: hidden;
        container-type: size;

        .item-property {
            align-self: stretch;
            min-width: 0;
            overflow: hidden;
            white-space: pre-wrap;
            font-style: italic;
            display: -webkit-box;
            line-clamp: 2;
            -webkit-line-clamp: 2;
            -webkit-box-orient: vertical;
            word-wrap: normal;
            text-align: center;
            font-size: 12px;
            font-size: clamp(10px, 4.5cqw, 16px);
        }

        .item-property:first-child {
            font-style: normal;
            font-weight: 600;
            font-size: 12px;
        }

        &:has(.item-property:only-child) {
            justify-content: center;
            padding: 16px;
            .item-property {
                line-height: 18px;
                margin-top: 0;
                padding-top: 0;
            }
        }
    }

    &.item-cell-large {
        .item-metrics .item-metric-group {
            &.bare .item-metric-header-group,
            &.bare .item-metric-header-name,
            &:not(.bare) .item-metric-header-group {
                font-size: $font-size-metric-large;
            }
        }
    }

    .item-metrics {
        background-color: white;
        align-self: stretch;
        flex: 1 auto;
        display: flex;
        flex-direction: column;
        justify-content: flex-end;
        overflow: hidden;
        border-top: $border;
        // border-bottom: 1px solid darken($border-color, 12%);


        &.hide {
            display: none;
        }

        .item-metric-group {
            // &:nth-child(odd) {
            //     background: lighten($body-background-color, 3%);
            // }

            overflow: hidden;
            flex: 0 1 auto;
            display: flex;
            flex-direction: column;
            align-self: stretch;
            overflow: hidden;
            vertical-align: middle;

            display: flex;
            flex-direction: column;
            align-items: stretch;
            justify-content: flex-start;
            color: $mid-grey;

            &:not(:first-child) {
                border-top: 1px solid $border-color;
            }

            .item-metric {
                display: inline-flex;
                flex-direction: row;
                justify-content: stretch;
                align-items: center;
                max-width: 100%;
                line-height: 1;
            }

            &:not(.bare) {
                padding-bottom: 2px;

                > .item-metric-header-group {
                    display: inline-block;
                    flex: 0 1 auto;
                    padding: 0;
                    padding-left: 6px;
                    padding-right: 6px;
                    vertical-align: middle;
                    max-height: 22px;
                    // background-color: darken($item-grid-cell-bgcolor, 2%);
                    // box-shadow: inset 0px -4px 3px 0px white;
                    // border-bottom: $border;
                }
                .item-metric-header-group .item-metric:not(:first-child) {
                    border-top: none;
                }
                .item-metric-values {
                    padding-top: 2px;
                    padding-bottom: 2px;
                    flex: 0 auto;
                    display: flex;
                    flex-direction: column;
                    align-items: stretch;
                    justify-content: space-evenly;
                    padding-right: 5px;
                    padding-left: 8px;
                }
                .item-metric {
                    align-self: stretch;
                    flex: 1 auto;
                    justify-content: space-between;
                    height: 13px;
                }
                .item-metric-header-name {
                    flex: 0 1 auto;
                    font-size: 8.5px;
                }
                .item-metric-value {
                    align-self: flex-end;
                    flex: 0 auto;
                }
            }

            &.bare {
                flex-direction: row;
                align-items: center;
                justify-content: space-between;
                margin: 0;
                padding: 2px 6px;
                height: 26px;

                .item-metric {
                    align-self: stretch;
                    flex: 0 auto;
                    justify-content: stretch;
                    align-items: stretch;
                }
                .item-metric-header-group {
                    flex: 1 0 auto;
                    display: flex;
                    align-items: center;
                    container-type: size;
                    span {
                        flex: 0 auto;
                        vertical-align: middle;
                        display: inline-block;
                    }
                }
                .item-metric-header-name {
                    flex: 0 auto;
                }
                .item-metric-value {
                    flex: 0 auto;
                    text-align: right;
                }
                .item-metric-header-name ~ .item-metric-value {
                    flex: 0 auto;
                }
            }

            &.bare .item-metric-header-group > span,
            &.bare .item-metric-header-name {
                display: -webkit-box;
                overflow: hidden;
            }

            &.bare .item-metric-header-group > span,
            &.bare .item-metric-header-name,
            &:not(.bare) .item-metric-header-group > span {
                font-size: $font-size-metric-default;
                font-weight: 600;
                white-space: pre-wrap;
                word-break: break-word;
                line-clamp: 2;
                -webkit-line-clamp: 2;
                -webkit-box-orient: vertical;
                word-wrap: normal;
            }

            .item-metric-value {
                align-self: center;
                line-height: 1;
                display: block;
                font-size: 10px;
                margin: 0;
                margin-left: 4px;
                padding-left: 3px;
                padding-right: 3px;

                // These must be the same for every "item-metric-value"
                // otherwise the grid heights become uneven.
                padding-top: 1px;
                padding-bottom: 1.25px;

                color: $dark-grey;
                &.blank {
                    &::after {
                        content: '—'; // – Ø
                        opacity: 0.4;
                    }
                }
            }

            .item-metric-group ~ .item-metric-value,
            .item-metric-name ~ .item-metric-value {
                margin-left: 4px;
            }

            .item-metric-value.percent-negative,
            .item-metric-value.percent-positive {
                margin-right: 0px;
                border-radius: 4px;
            }

            .item-metric-value.percent-negative {
                background: rgba($red, 0.05);
            }
            .item-metric-value.percent-positive {
                background: rgba($green, 0.05);
            }
        }
    }
}
