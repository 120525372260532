.checkbox-container {
    input[type='checkbox'] {
        margin: 0;
        position: relative;
        width: 14px;
        height: 14px;
        display: flex;
        justify-content: center;
        align-items: center;
        color: $darker-grey;
        border: 1px solid $mid-grey;
        border-radius: 2px;
        appearance: none;
        outline: 0;
        cursor: pointer;
        transition: background 0 cubic-bezier(0.1, 0.1, 0.25, 1);

        &::before {
            position: absolute;
            content: '';
            display: flex;
            justify-content: center;
            align-items: center;
            width: 2px;
            height: 7px;
            border-style: solid;
            border-color: $white;
            border-width: 0 2px 2px 0;
            transform: rotate(45deg);
            opacity: 0;
        }

        &:checked {
            color: $white;
            border-color: $blue;
            background: $blue;

            &::before {
                opacity: 1;
            }
        }
    }

    &.exclude input[type='checkbox']:checked {
        border-color: $light-red;
        background: $light-red;
    }
}
