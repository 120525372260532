
.view-customers {

    $header-height: 118px;
    $subfilter-height: -10px;

    position: absolute;
    top: 20px; left: 40px; right: 40px; bottom: 0px;

    .customer-stats {
        position: absolute;
        left: 0;
        right: 0;
    }

    .icon-left-open-mini, .icon-right-open-mini {
        font-size: 24px;
    }

    .customer-list-container {
        position: absolute;
        top: $header-height + $subfilter-height + 20px;
        bottom: 0; left: 0; right: 0;
        overflow: hidden;

        $actions-height: 40px;

        .customer-actions {
            position: absolute;
            left: 0; right: 0; top: 0;
            height: $actions-height;
            background-color: $white;
            color: $mid-grey;
            z-index: $z-base;
            border-bottom: 1px solid $white;

            .customer-count {
                line-height: $actions-height;
                font-size: 13px;
                margin-left: 12px;

                .value {
                    font-weight: 600;
                }
            }

            button {
                color: inherit;
                display: block;
                border: none;
                background: none;
                height: 100%;

                &:hover {
                    text-decoration: underline;
                    color: $darker-grey;
                }
            }

            .button-export {
                float: right;
                padding: 0 25px;
                border-left: $border;

                .loadable.loading:after {
                    transform: scale(0.5,0.5) translate(0px, 3px);
                }
            }

            .customer-count {
                float: left;
                display: inline-block;
            }

            .controls-pagination {
                float: left;
                display: inline-block;
            }
        }

        .customer-list {
            border-top: $border;
            position: absolute;
            top: $actions-height;
            bottom: 0; left: 0; right: 0;

            .column-name {
                text-align: left;
                width: 220px;
            }

            .column-transaction-count {
                width: 100px;
            }

            .column-input {
                input {
                    background-color: $white;

                    &::-webkit-textfield-decoration-container {
                        visibility: hidden;
                    }
                }
            }

        }
    }

    @import 'view-customers/customer-stats';
    @import 'view-customers/pagination-controls';
}
