.ui-metric-selector-tree-container {
    display: inline-block;
    height: 100%;

    button.metric-expand {
        width: 90px;
        height: 48px;
        padding: 3px;
        position: relative;
        top: 24px;
        margin-right: 10px;
        display: inline-block;
        text-align: center;
        cursor: pointer;
        user-select: none;

        border-radius: 6px;
        background: $lighter-grey;
        box-shadow:
            inset 0px 0px 0px 1px $white,
            0px 1.2px 1px 0.4px rgba(black, 0.06);
        border: 1px solid darken($border-color, 5%);
        border-bottom-width: 1.5px;
        font-size: 10px;
        text-transform: uppercase;
        letter-spacing: 1px;
        color: $dark-grey;

        i {
            width: auto;
            color: rgba(darken($border-color, 20%), 1);
            font-size: 12px;
            margin-left: -15px;
            opacity: 1;
        }
        span {
            font-weight: normal;
            font-size: 9px;
            width: 40px;
            display: inline-block;
            vertical-align: middle;
            text-align: center;
            line-height: 1.3;
        }

        &:focus {
            box-shadow: none;
        }

        &:hover {
            background-color: darken($lighter-grey, 2%);
            i, span {
                color: $blue;
            }
        }

        &.active {
            border-color: rgba($blue, 0.5);
            background: rgba($blue, 0.1);
            box-shadow: inset 0 1px 1px rgba($navy, 0.1);
            i, span {
                color: $blue;
            }
        }
    }
}

$top-ui-metric-selected: 72px;

.ui-metric-selector {
    position: absolute;
    min-height: 400px;
    max-height: calc(90% - $top-ui-metric-selected);
    width: 360px;
    right: 10px;
    top: $top-ui-metric-selected;
    z-index: $z-modal;
    border: $border;
    background: white;
    display: flex;
    flex-direction: column;
    box-shadow: 0px 3px 7px rgba(black, 0.08);
    padding-bottom: 4px;

    $header-height: 80px;

    & > header {
        height: $header-height;
        border-bottom: $border;

        .metric-selector-filter {
            display: flex;
            align-items: center;
            justify-content: center;

            button {
                line-height: 100%;
                height: $header-height - 9px;
                display: block;
                border-left: $border;
                margin-right: 3px;
                font-size: 11px;
                min-width: 70px;
                color: $mid-grey;

                &:not(.active) {
                    display: none;
                }
            }

            input {
                flex-grow: 1;
                height: $header-height - 8px;
                border: none;
                display: block;
                padding-right: 6px;
                font-size: 12px;
            }

            i {
                display: block;
                width: 30px;
                font-size: 13px;
                color: $mid-grey;
                opacity: 0.6;
                height: $header-height - 1px;
                line-height: $header-height - 1px;
                text-align: center;
            }
        }
    }

    .metric-selector-tree {
        max-height: calc(100% - $top-ui-metric-selected - $header-height);

        overflow-y: scroll;
        .jstree-anchor {
            color: $dark-grey;
            font-size: 13px;
            .jstree-checkbox {
                margin-right: 4px;
            }
        }
        .jstree-search {
            color: $darker-grey;
        }
    }
}
