
.natural-language-filter-display {
    cursor: default;
    overflow: hidden;
    user-select: none;

    .display-bar-content {
        height: 38px;
        width: 100%;
        display: flex;
        position: relative;

        .display-bar-message {
            position: absolute;
            top: 0;
            left: 1em;
            z-index: 1;
            display: flex;
            flex-wrap: nowrap;
            white-space: nowrap;
            overflow: auto;

            .display-bar-property-filter {
                margin-left: 2px;
                display: flex;
                align-items: center;
            }

            .display-bar-single-title {
                margin-left: 2px;
                padding: 0 2px;
                display: flex;
                color: $dark-grey;
                font-weight: 600;
            }

            .display-bar-property-filter-name {
                padding: 0 2px;
                display: flex;
                text-transform: capitalize;
                color: $dark-grey;
            }

            .display-bar-property-filter-items {
                display: flex;
                height: 26px;
                align-items: center;
                padding: 0 8px;
                margin: 0 2px;
                border: 1px solid $blue;
                border-radius: 20px;
                cursor: pointer;
                opacity: 0.8;

                &:hover {
                    opacity: 1;
                    .value {
                        color: $dark-grey;
                    }
                }

                .value,
                .property {
                    height: 100%;
                    display: flex;
                    align-items: center;
                }

                .value::before {
                    content: '|';
                    height: 100%;
                    margin: 0 2px;
                    display: flex;
                    align-items: center;
                }

                .property {
                    font-size: 11px;
                    font-weight: 600;
                    padding: 0 2px;
                    color: $blue;
                }

                .value {
                    padding-left: 2px;
                    font-size: 12px;
                    font-weight: 500;
                    color: $mid-grey;
                    height: 26px;

                    display: flex;
                }

                &.excluded {
                    border-color: $red;

                    .property {
                        color: $red;
                    }

                    .value {
                        text-decoration: line-through;
                    }
                }
            }

            .property-value-item {
                display: flex;
                flex-wrap: nowrap;
                align-items: center;

                .value-separator {
                    margin: 0 2px;
                }
            }

            &.drawing,
            &.drawing .display-bar-property-filter-name,
            &.drawing .display-bar-property-filter-items > * {
                color: transparent;
            }
        }
    }
}
