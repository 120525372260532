.animation-fade {
    &.ng-enter, &.ng-leave {
        transition: all .33s cubic-bezier(0.694, 0.0482, 0.335, 1);
    }

    &.ng-enter {
        opacity: 0;
        &.ng-enter-active {
            opacity: 1;
        }
    }

    &.ng-leave {
        opacity: 1;
        &.ng-leave-active {
            opacity: 0;
        }
    }
}


.animation-slide-down {
    &.ng-enter, &.ng-leave {
        transition: all .22s cubic-bezier(0.694, 0.0482, 0.335, 1);
    }

    &.ng-enter {
        transform: translate(0, -100%);
        &.ng-enter-active {
            transform: translate(0, 0);
        }
    }

    &.ng-leave {
        transform: translate(0, 0);
        &.ng-leave-active {
            transform: translate(0, -100%);
        }
    }
}

.animation-slide-bottomleft {
    &.ng-enter, &.ng-leave {
        transition: all .22s cubic-bezier(0.694, 0.0482, 0.335, 1);
    }

    &.ng-enter {
        opacity: 0;
        transform: translate(0, -10px);
        &.ng-enter-active {
            opacity: 1;
            transform: translate(0px, 0px);
            // transform: translate(0, 0);
        }
    }

    &.ng-leave {
        opacity: 1;
        // transform: translate(0, 0);
        transform: translate(0px, 0px);
        &.ng-leave-active {
            opacity: 0;
            // transform: translate(-10px, 10px);
            transform: translate(0, 10px);
        }
    }
}


@keyframes rotate-36 {
    2.777% {
        background-color: hsl(10, 100%, 75%);
    }
    5.555% {
        background-color: hsl(20, 100%, 75%);
    }
    8.333% {
        background-color: hsl(30, 100%, 75%);
    }
    11.111% {
        background-color: hsl(40, 100%, 75%);
    }
    13.888% {
        background-color: hsl(50, 100%, 75%);
    }
    16.667% {
        background-color: hsl(60, 100%, 75%);
    }
    19.444% {
        background-color: hsl(70, 100%, 75%);
    }
    22.222% {
        background-color: hsl(80, 100%, 75%);
    }
    25% {
        background-color: hsl(90, 100%, 75%);
    }
    27.777% {
        background-color: hsl(100, 100%, 75%);
    }
    30.555% {
        background-color: hsl(110, 100%, 75%);
    }
    33.333% {
        background-color: hsl(120, 100%, 75%);
    }
    36.111% {
        background-color: hsl(130, 100%, 75%);
    }
    38.888% {
        background-color: hsl(140, 100%, 75%);
    }
    41.667% {
        background-color: hsl(150, 100%, 75%);
    }
    44.444% {
        background-color: hsl(160, 100%, 75%);
    }
    47.222% {
        background-color: hsl(170, 100%, 75%);
    }
    75% {
        background-color: hsl(180, 100%, 75%);
    }
    52.777% {
        background-color: hsl(190, 100%, 75%);
    }
    55.555% {
        background-color: hsl(200, 100%, 75%);
    }
    58.333% {
        background-color: hsl(210, 100%, 75%);
    }
    61.111% {
        background-color: hsl(220, 100%, 75%);
    }
    63.888% {
        background-color: hsl(230, 100%, 75%);
    }
    66.667% {
        background-color: hsl(240, 100%, 75%);
    }
    69.444% {
        background-color: hsl(250, 100%, 75%);
    }
    72.222% {
        background-color: hsl(260, 100%, 75%);
    }
    75% {
        background-color: hsl(270, 100%, 75%);
    }
    77.777% {
        background-color: hsl(280, 100%, 75%);
    }
    80.555% {
        background-color: hsl(290, 100%, 75%);
    }
    83.333% {
        background-color: hsl(300, 100%, 75%);
    }
    86% {
        background-color: hsl(310, 100%, 75%);
    }
    88.888% {
        background-color: hsl(320, 100%, 75%);
    }
    91.667% {
        background-color: hsl(330, 100%, 75%);
    }
    94.444% {
        background-color: hsl(340, 100%, 75%);
    }
    97.222% {
        background-color: hsl(350, 100%, 75%);
    }
    100% {
        background-color: hsl(360, 100%, 75%);
    }
}
