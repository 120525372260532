.button-close {
    position: relative;
    display: inline-block;
    width: 13px;
    height: 13px;
    position: relative;
    top: 3px;
    left: 5px;
    cursor: pointer;
    transition: all 0.1s;

    i {
        transition: opacity 0.1s;
        line-height: 13px;
        font-size: 13px;
        margin: 0;
        padding: 0;
        position: absolute;
        left: -3px;
        right: 0;
        bottom: 0;
        top: 0;
    }

    .icon-cancel {
        color: $mid-grey;
    }

    .icon-cancel-circled {
        opacity: 0;
        color: rgba($red, 0.8);
    }

    &:hover {
        .icon-cancel {
            opacity: 0;
        }
        .icon-cancel-circled {
            opacity: 1;
        }
    }
}
