$ag-compatibility-mode: 'legacy';
@import '~@ag-grid-community/core/dist/styles/ag-grid.scss';
@import '~@ag-grid-community/core/dist/styles/ag-theme-alpine/sass/ag-theme-alpine.scss';

@import '../utils/variables';

.ag-theme-alpine {
    transition: none;
    font-family: $font-stack;

    $grid-border-color: darken($border-color, 2%);
    $grid-border-color-darker: darken($grid-border-color, 4%);

    .ag-root-wrapper,
    .ag-root {
        border: none;
        background-color: $body-background-color;
    }
    .ag-ltr .ag-header-cell-resize {
        width: 5px;
        right: -2px;
        &::after {
            padding: 0;
            margin: 0;
            height: 100%;
            top: 0;
            right: -2px;
            width: 4px;
            background-color: transparent;
        }
        &:hover, &:active {
            &::after {
                background-color: $grid-border-color-darker;
            }
        }
    }
    .ag-popup-backdrop {
        z-index: $z-overlay;
    }
    .ag-header {
        background: $white;
        z-index: $z-elevated + 1;
    }
    .ag-header-group-cell {
        font-weight: 600;
        letter-spacing: 0.6px;
    }
    .ag-cell:not(.ag-cell-focus) {
        border: 0.5px solid transparent;
        border-right: 0.5px solid $grid-border-color;
        border-left: 0.5px solid $grid-border-color;
        &.column-group-end {
            border-right-width: 1px;
        }
        &.column-group-start {
            border-left-width: 1px;
        }
    }
    .ag-cell.ag-cell-focus {
        border: 0.5px solid $blue !important;
        &.cell-value-group-end {
            text-indent: -1px;
        }
    }

    .ag-cell {
        font-size: 13px;
        display: flex;
        justify-content: center;
        align-items: center;
        outline: none;
        color: $mid-grey;
        line-height: 1.3;
        padding: 6px 12px;

        & > span, a {
            letter-spacing: 0.2px;
        }

        & > span {
            display: block;
            display: -webkit-box;
            -webkit-line-clamp: 3;
            -webkit-box-orient: vertical;
            word-wrap: normal;
            overflow: hidden;
            text-align: center;
        }

        a {
            color: $blue;
            text-decoration: underline;
            text-decoration-thickness: 0.5px;
            text-underline-offset: 0.1rem;
        }
    }

    .ag-center-cols-clipper {
        z-index: 0;
    }

    // The real header cells


    .ag-header-container {
        border: none;
        // border-bottom: 1px solid darken($border-color, 5%);
        // border-bottom: 1px solid $border-color;
    }

    .ag-header {
        border: none;
        border-bottom: 1px solid $grid-border-color-darker;
        .ag-header-row {
            border: none;
            &:not(:first-child) {
                border-top: 0.5px solid $grid-border-color;
            }
            &:not(:last-child) {
                border-bottom: 0.5px solid $grid-border-color;
            }
            .ag-header-cell,
            .ag-header-group-cell {
                font-size: 10px;
                color: $dark-grey;
                text-transform: uppercase;
                border: none;
                border-left: 0.5px solid $grid-border-color;
                border-right: 0.5px solid $grid-border-color;
                overflow: visible;
                &.column-group-end {
                    border-right-width: 1px;
                }
                &.column-group-start {
                    border-left-width: 1px;
                }
            }
        }
    }

    // The border between the body and the left pinned panel
    .ag-pinned-left-header,
    .ag-pinned-left-cols-container,
    .ag-pinned-left-floating-top {
        z-index: 1;
        border: none;
        box-shadow: 0px -1px 0px 1px darken($grid-border-color, 2%) !important;
        .ag-header-row, .ag-row {
            border-right: none;
        }
        .ag-cell.ag-cell-last-left-pinned,
        .ag-header-row .ag-header-cell:last-child,
        .ag-header-row .ag-header-group-cell:last-child {
            &.ag-cell-focus {
                border-color: darken($grid-border-color, 2%) !important;
            }
            border-right: 0.5px solid darken($grid-border-color, 2%) !important;
            border-left: 0.5px solid darken($grid-border-color, 2%) !important;

            &.column-group-end {
                border-right-width: 1px !important;
            }
            &.column-group-start {
                border-left-width: 1px !important;
            }
        }
    }

    // The total row
    .ag-floating-top,
    .ag-pinned-left-floating-top {
        border: none;
        .ag-row {
            background-color: $lighter-grey;
        }
        .ag-cell {
            border-left: 0.5px solid $grid-border-color-darker;
            &.column-group-start {
                border-left-width: 1px;
            }
            &:not(.ag-cell-last-left-pinned) {
                border-right: 0.5px solid $grid-border-color-darker;
                &.column-group-end {
                    border-right-width: 1px;
                }
            }
        }
    }

    // The pinned values in the total bar...
    .ag-pinned-left-floating-top .ag-cell {
        text-transform: uppercase;
        font-style: italic;
        font-size: 10px !important;
    }

    .ag-floating-top {
        border-bottom: $border;
    }

    .ag-cell, .ag-header-cell, .ag-header-group-cell {
        box-shadow: inset 0px 0px 0px 0.5px white;
    }

    .ag-header-cell-label, .ag-header-group-cell-label {
        white-space: unset;
        height: 100%;
        width: 100%;
    }

    .ag-header-group-cell-label {
        display: flex;
        justify-content: center;
    }


    .column-group-start,
    .column-group-end {
        border-width: 1px;
    }

    .ag-header-cell {
        padding-left: 8px;
        padding-right: 8px;

        .ag-header-cell-text {
            flex: 1 1 auto;
            display: flex;
            justify-content: center;
            font-style: normal;
            color: $mid-grey;
            font-weight: 400;
        }

        &.ag-header-cell-filtered {
            background: rgba(41, 153, 255, 0.1);

            &:hover {
                .ag-header-icon.ag-header-cell-menu-button:hover + .ag-header-cell-label {
                    .ag-filter-icon .ag-icon::before {
                        box-shadow: inset 0px 0px 0px 1px #fff, 0px 1.2px 1px 0.4px rgba(0, 0, 0, 0.1);
                        background-color: #f3f3f5;
                        border-color: #d6dde5;
                    }
                }
            }


            .ag-filter-icon {
                z-index: 0;
                padding-right: 18px;
                width: 18px;
                color: $blue;
                margin-left: 0px !important;
                position: relative;
                pointer-events: none;
                .ag-icon::before {
                    border: 1px solid transparent;
                    background-color: transparent;
                    font-size: 18px !important;
                    border-radius: 4px;
                    position: relative;
                    top: 1px;
                }
            }

            .ag-header-cell-menu-button {
                position: absolute;
                opacity: 0;
                z-index: 2;
            }

            .ag-cell-label-container .ag-header-cell-label .ag-header-cell-text {
                color: $blue;
                font-weight: 700;
            }
        }

        &.ag-header-cell-sortable {
            .ag-header-cell-sorted-none .ag-header-label-icon.ag-sort-none-icon {
                display: block !important;
            }

            :not(.ag-header-cell-menu-button) + .ag-header-cell-label .ag-header-cell-text {
                margin-left: 22px !important;
            }
        }

        &:not(.ag-header-cell-sortable) {
            .ag-header-cell-menu-button + .ag-header-cell-label .ag-header-cell-text {
                margin-right: 22px !important;
            }
        }

        .ag-cell-label-container {
            flex-direction: row;
            justify-content: flex-start;

            .ag-header-cell-label {
                .ag-sort-none-icon,
                .ag-sort-descending-icon,
                .ag-sort-ascending-icon,
                .ag-sort-order {
                    order: 3;
                }

                .ag-header-cell-text {
                    z-index: 3;
                    order: 2;
                }
            }
        }

        // &.ag-header-cell-filtered {
        //     .ag-cell-label-container .ag-header-cell-label .ag-header-cell-text {
        //         padding-right: 22px;
        //     }

        //     .ag-cell-label-container.ag-header-cell-sorted-none .ag-header-cell-label .ag-header-cell-text {
        //         padding-right: 40px;
        //     }
        // }

    }

    .ag-header-cell-menu-button {
        color: $mid-grey;
    }

    #overlay {
        background: rgba(white, 0.75);
        pointer-events: auto !important;
        z-index: $z-overlay;
    }

    .ag-overlay-no-rows-wrapper {
        background-color: $white;

        .no-rows-message-container,
        .error-message-container {
            user-select: none;
            height: 160px;
            width: 100%;
            display: flex;
            align-items: center;
            justify-content: center;
            background: $white;
            font-size: 14px;
            text-align: center;
            span {
                color: $mid-grey;
                border-radius: 15px;
                padding: 5px 12px;
                background: rgba($light-grey, 0.1);
            }
        }

        .error-message-container {
            span {
                color: $red;
                background: rgba($light-red, 0.1);
            }
            i {
                color: $light-red;
                font-size: 42px;
                margin: 20px;
                display: block;
                opacity: 0.8;
            }
        }
    }

    .ag-overlay-loading-center {
        display: flex;
        height: 100%;
        width: 100%;
        justify-content: center;
        align-items: center;
        flex-direction: column-reverse;
        z-index: 2;
        color: transparent;
        box-shadow: none;

        &:after {
            width: 100px;
            height: 70px;
            background: transparent url('~images/spinner.gif') no-repeat;
            background-position: center center;
            background-size: 100px;
            z-index: 4;
            background: transparent url('~images/spinner.gif') no-repeat;
            background-position: center center;
            background-size: 50px;
            content: ' ';
        }
    }
    .ag-header-cell-menu-button:hover {
        border: none;
        cursor: pointer;
    }
    .ag-header-icon {
        color: $light-grey;
        .ag-icon-asc::before,
        .ag-icon-desc::before {
            color: $blue;
        }
    }

    .ag-icon.ag-icon-menu {
        font-size: 18px;
        line-height: 18px;
    }
    .ag-row {
        transition: none !important;
    }
    .ag-header {
        background-color: lighten($border-color, 8%);
    }
    .ag-row-odd {
        background-color: $lighter-grey;
    }
    .ag-row-even {
        background-color: #fff;
    }
    .ag-loading-panel {
        background-color: rgba(255, 255, 255, 0.5);
    }
    .ag-loading-center {
        background-color: #fff;
        border: $border;
        border-radius: 10px;
        padding: 10px;
    }
    .ag-row-selected {
        background-color: #b0e0e6;
    }
    .ag-group-cell-entire-row {
        background-color: #aaa;
        padding: 4px;
    }
    .ag-footer-cell-entire-row {
        background-color: #aaa;
        padding: 4px;
    }
    .ag-group-cell {
        font-style: italic;
    }
    .ag-footer-cell {
        font-style: italic;
    }
    .ag-filter-checkbox {
        position: relative;
        top: 2px;
        left: 2px;
    }
    .ag-filter-header-container {
        border-bottom: 1px solid #d3d3d3;
    }
    .ag-filter {
        border: 1px solid #000;
        background-color: #f0f0f0;
    }
    .ag-selection-checkbox {
        margin-left: 4px;
    }
    .ag-paging-panel {
        padding: 4px;
    }
    .ag-paging-button {
        margin-left: 4px;
        margin-right: 4px;
    }
    .ag-paging-row-summary-panel {
        display: inline-block;
        width: 300px;
    }
    .cell-blank-value {
        color: $light-grey;
    }
    .cell-label {
        text-transform: uppercase;
        letter-spacing: 1px;
        font-size: 12px;
    }
    .ag-filter-item > label {
        display: flex;
        justify-content: center;
    }
    .ag-filter-filter[type='text'] {
        width: 100%;
        border: none;
        margin: 0;
        font-size: 12px;
    }
    .ag-filter-value {
        color: $dark-grey;
        font-size: 13px;
        margin-left: 2px;
    }
    .ag-filter-header-container {
        color: $dark-grey;
        font-size: 13px;
    }
    .ag-filter {
        border: $border;
        background: white;
        box-shadow: 0px 3px 7px rgba(black, 0.08);
    }
}
