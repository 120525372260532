@import '~styles/utils/variables';
@import '~styles/base';

.properties-items {
    .properties-items-title {
        height: 36px;
        padding: 0 10px;
        display: flex;
        align-items: center;
        font-size: 10px;
        color: $mid-grey;
        text-transform: uppercase;
        font-weight: 600;
        letter-spacing: 1px;
        width: 100%;
    }

    .items-list {
        .ui-pellets .ui-pellet {
            border-radius: 4px;
        }
    }
}
