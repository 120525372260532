$status-color-notification: #a5a8ff;
$status-color-error: #ff5a5a;
$status-color-success: #71e191;

.status-message-bar {
    font-weight: 600;
    text-align: left;
    font-size: 12px;

    &:hover {
        cursor: pointer;
    }
    &:hover i.icon-close {
        cursor: pointer;
        opacity: 0.8;
    }
    i.icon-status {
        font-size: 12px;
        opacity: 0.7;
        padding-right: 5px;
        padding-left: 10px;
    }
    i.icon-close {
        transition: all 0.2s;
        height: $status-message-bar-height;
        width: $status-message-bar-height;
        position: relative;
        margin-right: 10px;
        display: inline-block;
        float: right;
        top: -2px;
        opacity: 0.4;
        &:hover {
            background: rgba(black, 0.1);
        }
        svg {
            display: block;
            height: $status-message-bar-height;
            width: $status-message-bar-height;
            padding: 8px;
        }
    }
    .status {
        border-top: 2px solid darken($status-color-success, 8%);
        border-bottom: 2px solid darken($status-color-success, 2%);
        background: $status-color-success;
        color: rgba(black, 0.8);
    }

    .status-error {
        border-top: 2px solid darken($status-color-error, 8%);
        border-bottom: 2px solid darken($status-color-error, 2%);
        background: $status-color-error;
    }

    .status-warning {
        border-top: 2px solid darken($status-color-notification, 8%);
        border-bottom: 2px solid darken($status-color-notification, 2%);
        background: $status-color-notification;
    }
}

.status-button {
    float: right;
    overflow: hidden;
    &:hover {
        cursor: pointer;
    }
    padding-left: 10px;
    padding-right: 10px;
    a {
        pointer-events: none;
    }
    i {
        opacity: 0.6 !important;
        position: relative;
        top: 0px !important;
    }
    &.status-success,
    &.status-notification {
        &.active {
            background: lighten($navy, 5%) !important;
        }
        &.active,
        &:hover,
        i {
            color: saturate($status-color-success, 0%) !important;
        }
    }
    &.status-warning {
        &.active {
            background: lighten($navy, 5%) !important;
        }
        &.active,
        &:hover,
        i {
            color: darken($status-color-notification, 10%) !important;
        }
    }
    &.status-error {
        &.active {
            background: lighten($navy, 5%) !important;
        }
        i {
            opacity: 0.7 !important;
        }
        &.active,
        &:hover,
        i {
            color: lighten($status-color-error, 3%) !important;
        }
    }


    @media (max-width: 1200px) {
        padding-left: 10px;
        padding-right: 10px;
    }
}
