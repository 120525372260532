
// Mixins for Rem Font Sizing
// http://css-tricks.com/snippets/css/less-mixin-for-rem-font-sizing/
@mixin font-size($size: 16){
  font-size: $size + px; // fallback
  font-size: ($size / 10) + rem;
}

@mixin placeholder {
    &::-webkit-input-placeholder { @content }
    &::-moz-placeholder { @content }
    &:-moz-placeholder { @content }
    &:-ms-input-placeholder { @content }
    &.placeholder { @content }
}

@mixin text-overflow-ellipsis {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

// =============================================================================
// String Replace
// =============================================================================

@function str-replace($string, $search, $replace: "") {
	$index: str-index($string, $search);

	@if $index {
		@return str-slice($string, 1, $index - 1) + $replace + str-replace(str-slice($string, $index + str-length($search)), $search, $replace);
	}

	@return $string;
}

// =============================================================================
// Font Face
// =============================================================================

@mixin font-face($name, $path, $weight: null, $style: null, $exts: eot woff2 woff ttf svg) {
	$src: null;

	$extmods: (
		eot: "?",
		svg: "#" + str-replace($name, " ", "_")
	);

	$formats: (
		otf: "opentype",
		ttf: "truetype"
	);

	@each $ext in $exts {
		$extmod: if(map-has-key($extmods, $ext), $ext + map-get($extmods, $ext), $ext);
		$format: if(map-has-key($formats, $ext), map-get($formats, $ext), $ext);
		$src: append($src, url(quote($path + "." + $extmod)) format(quote($format)), comma);
	}

	@font-face {
		font-family: quote($name);
		font-style: $style;
		font-weight: $weight;
		src: $src;
	}
}
