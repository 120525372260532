@use 'sass:math';

// within: .smart-groups-filter
.smart-group-filter-items {
    position: absolute;
    left: 0; top: 0; bottom: 0; right: 0;

    $header-height: 100px;
    $footer-height: 0px;
    $sidebar-width: 272px;

    .property-filters {
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        position: absolute;
        background: $lighter-grey;
        white-space: nowrap;

        .dimensions-sidebar {
            height: 100%;
            width: $sidebar-width;
        }

        .item-properties {
            position: absolute;
            top: 0;
            bottom: 0;
            width: $sidebar-width;
            left: 0;
            background: $body-background-color;
            padding: 10px;
            overflow-y: scroll;

            .property-item {
                display: flex;
                min-height: 40px;
                justify-content: center;
                align-items: center;
                padding: 8px 8px;
                margin-bottom: 16px;
                border: 1px solid darken($border-color, 3%);
                border-bottom-width: 1.5px;
                border-radius: 6px;
                background: $white;
                transition: none;
                color: $dark-grey;
                font-size: 10px;
                letter-spacing: 1px;
                text-transform: uppercase;
                font-weight: 500;
                white-space: pre-wrap;
                text-align: center;
                word-break: break-word;
                line-height: 1.4;

                 &:hover {
                  cursor: pointer;
                  background: rgba($lighter-grey, 0.5);
                }

                &:active {
                    box-shadow: none;
                    top: 1px;
                }
            }

            button {
                display: block;
                position: relative;
                overflow: hidden;
                width: 100%;
                line-height: 25px;
                padding: 3px 0;
                margin: 0 0 15px 0;
                text-align: center;

                outline: none;
                border: $border;
                // box-shadow: 0px 1px 0px 0px $border-color;
                box-shadow: none;
                border-radius: 6px;
                background: $white;
                border-radius: 6px;
                color: $dark-grey;
                font-size: 10px;
                letter-spacing: 1px;
                text-transform: uppercase;
                font-weight: 500;

                word-wrap: break-word;
                white-space: normal;
                line-height: 1.4;

                &:hover {
                  cursor: pointer;
                  background: rgba($lighter-grey, 0.5);
                }

                &:active {
                    box-shadow: none;
                    top: 1px;
                }
            }
        }


        .item-property-filter {
            flex: 0 0 230px;
            position: relative;
            border-right: $border;

            .item-property-filter-list:not(.error) .message-container {
                display: none;
            }

            .item-property-filter-list.error .message-container {
                user-select: none;
                position: absolute;
                height: 100%;
                top: 0;
                left: 0;
                bottom: 0;
                right: 0;
                z-index: $z-elevated;
                display: flex;
                align-items: center;
                justify-content: center;
                background: $white;
                font-size: 14px;
                text-align: center;
                .message {
                    display: flex;
                    flex-direction: column;
                }
                span {
                    color: $red;
                    border-radius: 15px;
                    background: rgba($light-red, 0.1);
                }
                i {
                    color: $light-red;
                    font-size: 42px;
                    margin-bottom: 20px;
                    display: block;
                    opacity: 0.8;
                }
            }


            .item-property-filter-header {
                display: flex;
                flex-direction: column;
                background: $body-background-color;

                .description {
                    line-height: 1.4;
                    height: 52px;
                    width: 100%;
                    display: flex;
                    padding-left: 14px;
                    padding-right: 7px;
                    align-items: center;
                    justify-content: flex-end;

                    .property-label {
                        height: 100%;
                        width: 100%;
                        display: flex;
                        align-items: center;
                        text-transform: uppercase;
                        font-size: 10px;
                        font-weight: 700;
                        letter-spacing: 1px;
                        color: $dark-grey;
                        overflow: hidden;
                        word-wrap: break-word;
                        white-space: normal;
                    }

                    .remove-property {
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        flex: 0 0 24px;
                        cursor: pointer;
                        font-size: 24px;
                        font-weight: 200;
                        color: rgba($dark-grey, 0.7);
                        &:hover {
                            color: $blue;
                        }
                    }
                }
            }

            .exclude {
                .toggle-mode {
                    .toggle-title.include-mode {
                        color: $light-grey;
                    }

                    .toggle-title.exclude-mode {
                        color: $light-red;
                    }
                }

                .item-property-values .item-property-value {
                    .checkbox-container input[type='checkbox']:checked {
                        border-color: $light-red;
                        background: $light-red;
                    }

                    &:not(.unavailable):hover {
                        color: $light-red;
                        background: $lighter-grey;
                    }

                    &.selected {
                        color: $light-red;
                    }

                }
            }

            .toggle-mode {
                background-color: $white;
                width: 100%;
                height: 44px;
                display: flex;
                justify-content: center;
                align-items: center;
                border-bottom: $border;

                .toggle-title {
                    width: 60px;
                    color: $light-grey;
                    display: flex;
                    justify-content: center;
                    text-transform: uppercase;
                    font-size: 9px;
                    font-weight: 700;
                    letter-spacing: 1px;
                    user-select: none;

                    &.include-mode {
                        color: $blue;
                    }

                    &.exclude-mode {
                        color: $light-grey;
                    }
                }

                .switch-box {
                    display: flex;
                    margin: 0 4px;
                    height: 30px;
                    justify-content: center;
                    align-items: center;
                }

                input[type='checkbox'].switch {
                    font-size: 10px;
                    appearance: none;
                    width: 3.5em;
                    height: 1.5em;
                    background: $blue;
                    border-radius: 3em;
                    position: relative;
                    cursor: pointer;
                    outline: none;
                    transition: all 0.2s ease-in-out;
                }

                input[type='checkbox'].switch:checked {
                    background: $light-red;
                }

                input[type='checkbox'].switch:after {
                    position: absolute;
                    content: '';
                    width: 1.5em;
                    height: 1.5em;
                    border-radius: 50%;
                    background: $white;
                    box-shadow: 0 0 0.25em rgba(0, 0, 0, 0.3);
                    transform: scale(0.7);
                    left: 0;
                    transition: all 0.2s ease-in-out;
                }

                input[type='checkbox'].switch:checked:after {
                    left: calc(100% - 1.5em);
                }
            }
        }

        .item-property-filters {
            position: absolute;
            left: $sidebar-width;
            top: 0;
            right: 0;
            bottom: 0;
            display: flex;
            overflow-x: scroll;
            overflow-y: hidden;
            border-left: $border;
            justify-content: flex-start;
            align-items: stretch;
            flex-direction: row;
            flex-wrap: nowrap;
            scroll-behavior: smooth;

            header {
                height: $header-height;
                border-bottom: 2px solid $border-color;
                padding: 15px 13px;

                input {
                    position: absolute;
                    display: block;
                    width: 100%;
                    font-size: 12px;
                    padding: 12px 10px 12px 39px;
                    border: none;
                    border-top: $border;
                    border-radius: none;
                    top: 53px;
                    left: 0px;

                    &:disabled {
                        opacity: 0.5;
                        background: $lighter-grey;
                    }
                }
            }

            main {
                position: absolute;
                border-top: $border;
                top: 52px;
                left: 0;
                bottom: 0;
                right: 0;

                display: flex;
                flex-direction: column;
                justify-content: stretch;
                align-items: stretch;

                .toggle-mode {
                    flex: 0 0 auto;
                }

                .item-property-values-container {
                    flex: 1;
                    max-height: 100%;
                }

                .filter-input-search {
                    position: relative;

                    .icon-search {
                        position: absolute;
                        top: 13px;
                        left: 10px;
                        font-size: 16px;
                        color: $mid-grey;
                    }

                    textarea[type='text'] {
                        resize: none;
                        min-height: 44px;
                        max-height: 200px;
                        width: 100%;
                        border-bottom: $border;
                        font-size: 12px;
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        padding-left: 40px;
                        padding-right: 30px;
                    }

                    .filter-input-search-buttons {
                        position: absolute;
                        right: 14px;
                        top: 10px;
                    }
                }

                .select-all-container {
                    height: 30px;
                    border-bottom: $border;
                    position: relative;

                    &:not(.disabled):hover {
                        color: $blue;
                    }

                    .tooltip-container {
                        position: absolute;
                        top: 24px;
                        left: 4px;
                        z-index: 2;
                        display: none;
                    }

                    &.unselect-all.selected .select-all-description {
                        .checkbox-container input[type='checkbox']:checked::before{
                            content: "-";
                            font-size: 28px;
                            margin-bottom: 6px;
                            transform: none;
                            border: none;
                        }
                    }

                    .select-all-description {
                        height: 100%;
                        padding: 0 12px;

                        label {
                            cursor: pointer;
                            height: 100%;
                            width: 100%;
                            display: flex;
                            align-items: center;
                        }

                        .checkbox-container {
                            background-color: $white;
                            margin-right: 12px;
                        }
                    }

                    .select-all-label {
                        text-transform: uppercase;
                        font-size: 10px;
                        letter-spacing: 0.5px;
                        user-select: none;
                    }

                    &.disabled {
                        cursor: not-allowed;
                        .select-all-description {
                            cursor: not-allowed;

                            .checkbox-container input[type='checkbox'] {
                                color: $lighter-grey;
                                background: $lighter-grey;
                                border-color: darken($lighter-grey, 10%);
                                cursor: not-allowed;
                            }

                            .select-all-label {
                                cursor: not-allowed;
                                color: darken($lighter-grey, 10%);
                            }
                        }

                        &:hover {
                            cursor: not-allowed;
                            .tooltip-container {
                                display: flex;
                            }
                        }
                    }
                }

                &.exclude .select-all-container {
                    .checkbox-container input[type='checkbox']:checked {
                        border-color: $light-red;
                        background: $light-red;
                    }

                    .checkbox-container.disabled input[type='checkbox']:checked,
                    .checkbox-container.disabled input[type='checkbox'] {
                        color: $lighter-grey;
                        background: $lighter-grey;
                        border-color: darken($lighter-grey, 10%);
                        cursor: not-allowed;
                    }

                    &:not(.disabled):hover,
                    &:not(.disabled).selected {
                        color: $light-red;
                    }

                    &:not(.unavailable):hover {
                        color: $light-red;
                        background: $lighter-grey;
                    }
                }
            }

            li {
                font-size: 14px;
                border-bottom: $border;
                background: $white;
                width: 100%;
                white-space: normal;

                &.unavailable {
                    color: rgba($mid-grey, 0.5);
                    border: none;
                    background: $lighter-grey;

                    .checkbox-container input[type='checkbox'],
                    .checkbox-container input[type='checkbox']:checked {
                        border-color: rgba($mid-grey, 0.5);
                        background: $lighter-grey, 0.5;
                        pointer-events: none;
                    }
                }

                &:not(.unavailable):hover {
                    color: $blue;
                    background: $lighter-grey;

                    .exclude {
                        color: $light-red;
                        background: $lighter-grey;
                    }
                }

                &:not(.unavailable) label {
                    cursor: pointer;
                }

                label {
                    height: 49px;
                    padding: 1em 0.5em;
                    position: relative;
                    display: flex;
                    align-items: center;

                    .checkbox-container {
                        margin-left: 5px;
                        margin-right: 10px;
                    }

                    .item-property-value-id {
                        font-size: 10px;
                        width: 230px;
                        overflow: hidden;
                        word-wrap: break-word;
                        width: 150px;
                        display: inline-block;
                        vertical-align: middle;
                    }

                    .item-property-value-count {
                        position: absolute;
                        top: 4px;
                        right: 4px;
                        font-size: 0.8em;
                        letter-spacing: 1px;
                        opacity: 0.7;
                    }
                }

                &.selected {
                    color: $blue;
                }

                .ui-match {
                    background: rgba($blue, 0.3);
                }
            }

            footer {
                position: absolute;
                bottom: 0;
                width: 100%;
                height: $footer-height;
                background-color: $white;
                line-height: $footer-height;
                border-top: $border;
                padding: 0 20px;
                color: #aaa;
                display: none;

                &:hover {
                    color: #888;
                }

                .property-details {
                    font-size: 10px;
                    float: right;
                    line-height: $footer-height;
                }
            }
        }
    }
}


.smart-groups-filter {
    $content-padding: 20px;

    & > .sidepanel .selected {
        position: absolute;
        top: 0; left: 0; right: 0; bottom: 0;
        background: $white;
        color: $dark-grey;
        font-size: 12px;
        line-height: 24px;
        border-left: $border;
        user-select: none;
        display: flex;
        flex-direction: column;
        align-items: stretch;
        padding-top: 0px;

        .selected-filters-title {
            padding: floor(math.div($content-padding, 3)) floor(math.div($content-padding, 1.5));
            text-transform: uppercase;
            font-size: 10px;
            letter-spacing: 1px;
            color: lighten($dark-grey, 5%);
            border-bottom: $border;
            background: #f3f3f5;
        }

        .selected-filters-properties {
            flex: 1 1 auto;
            overflow-y: scroll;
        }

        .selected-filters-property {
            margin-top: math.div($content-padding, 2);
            padding: 0px $content-padding;
            padding-bottom: math.div($content-padding, 2);
            &:not(:last-child) {
                border-bottom: $border;
            }
        }

        .selected-filters-property-title {
            margin-left: math.div($content-padding, 2.5) * -1;
            padding: math.div($content-padding, 16) 0px;
        }

        .selected-filters-property-values {
            margin-top: 6px;
            margin-left: -6px;
        }

        .property-value {
            display: inline-flex;

            color: $white;
            font-size: 11px;
            padding: 6px 9px;
            padding-bottom: 6px;
            border-radius: 6px;
            margin: 0.5em;
            line-height: 1.2;
            background: $blue;

            &.excluded {
                background: $light-red;
            }

            .property-value-label {
                user-select: text;
            }

            .property-value-icon {
                margin-left: 6px;
                cursor: pointer;
                display: flex;
                align-items: center;
                &:before {
                    line-height: auto;
                    width: auto;
                    margin: 0px;
                }
                &:hover {
                    transform: scale(1.1);
                }
            }
        }
    }
}


.smart-groups-filter-time {


    .smart-group-filter-time-panel {
        width: 100%;
        padding: 4px 4px;
        height: 100%;
        display: flex;
        align-items: stretch;
        justify-content: center;
        background: $white;
    }


    .calendar-popup-toggle-button {
        // transition: all .22s cubic-bezier(0.694, 0.0482, 0.335, 1);
        align-self: center;
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 6px 8px;
        height: calc(100% - 5px);
        border-radius: 5px;
        cursor: pointer;
        user-select: none;

        font-size: 11px;
        white-space: nowrap;
        border: 1px solid darken($border-color, 6%);
        border-bottom-width: 1.5px;
        background: $lighter-grey;
        color: $dark-grey;

        &:hover {
            background-color: darken($lighter-grey, 2%);
        }

        &.active {
            border-color: rgba($blue, 0.5);
            background: rgba($blue, 0.1);
            color: $blue;
            .icon-calendar, .timerange-start::after {
                color: rgba($blue, 0.7);
            }
        }
        .icon-calendar, .timerange-start::after {
            color: rgba(darken($border-color, 25%), 1);
        }

        .icon-calendar {
            flex: 0 0 16px;
            text-align: center;
            position: relative;
            top: 0px;
            margin-right: 2px;
            left: -2px;
        }
        .timerange {
            flex: 1 1 auto;
            display: flex;
            justify-content: center;
            text-transform: uppercase;
            font-weight: 600;
        }
        .timerange-start::after {
            content: 'to';
            font-style: italic;
            text-transform: lowercase;
            letter-spacing: 0px;
            padding: 0 5px;
            font-weight: 400;
        }

        @media (max-width: $navbar-collapse-breakpoint) {
            &.active {
                span, i {
                    color: inherit;
                }
            }
        }
    }

    .calendar-popup {
        position: absolute;
        top: 48px;
        right: 8px;
        z-index: $z-dropdown;

        &:not(.active) {
            display: none;
        }
    }

    @media (max-width: $navbar-collapse-breakpoint) {
        .calendar-popup {
            max-height: 80%;
            overflow: scroll;
            overflow-x: hidden;

            &:after {
                right: 65px;
            }
        }
    }
}
