.route-items #view {
    overflow: hidden !important;
}

.loadable-item-view:before {
    background: none !important;
}

#view > .view-items {
    position: absolute;
    top: 0; bottom: 0; left: 0; right: 0px;

    display: flex;
    flex-direction: column;
    justify-content: stretch;
    align-items: stretch;

    > header {
        flex: 0 0 auto;
    }

    > main {
        position: relative;
        flex: 1 1 auto;
        overflow: hidden;
    }

    .view-items-body {
        &.drop-mode .drag-and-drop-zone {
            height: 100%;
            width: 100%;
        }

        .drag-and-drop-zone {
            position: absolute;
            height: 0;
            width: 0;
            z-index: 6;
            opacity: 0.8;
            background: $white;
        }
    }
}

.view-items-container {
    $natural-language-query-height: 40px;
    $toggle-button-width: 100px;
    position: absolute;
    top: 0; bottom: 0; left: 0; right: 0;
    overflow: hidden;

    display: flex;
    flex-direction: column;
    justify-content: stretch;
    align-items: stretch;

    > header {
        position: relative;
        flex: 0 1 auto;
        z-index: $z-main-header;
    }

    > main {
        position: relative;
        flex: 0 1 auto;
        display: flex;
        flex-direction: column;
        position: relative;
        overflow: hidden;

        &.drop-mode .drag-and-drop-zone {
            height: 100%;
            width: 100%;
        }

        .drag-and-drop-zone {
            position: absolute;
            height: 0;
            width: 0;
            z-index: 6;
            opacity: 0.8;
            background: $white;
        }
    }

    .metrics-funnel-breadcrumb-actions-panel {
        flex: 0 0 auto;
        background-color: $white;
        display: flex;
        z-index: 1;

        .left-panel {
            display: flex;
            align-items: center;
            border-bottom: $border;
            border-right: $border;
        }
    }

    .action-header {
        .action-header-actions-panel-options {
            display: flex;
            .properties-items {
                height: 100%;
                width: 100%;
                border-bottom: $border;
                .items-list .ui-pellets .ui-pellet {
                    border-radius: 14px;
                }
            }

            .left-side {
                width: 100%;
            }
            .right-side {
                min-width: 110px;
                min-height: 140px;
                display: flex;
                flex-direction: column;
                border-left: $border;
                border-bottom: $border;
                padding: 4px 6px;

                .top-part {
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    height: 100%;
                    min-height: 60px;
                }

                .bottom-part {
                    display: flex;
                    flex-direction: column;
                    justify-content: flex-end;
                    align-items: center;
                    min-height: 60px;

                    & .secondary-simple-action-item:not(:last-child) {
                        margin-bottom: 10px;
                    }

                    .secondary-simple-action-item {
                        cursor: pointer;
                        display: flex;
                        width: 100%;
                        justify-content: flex-end;
                        align-items: center;
                        font-size: 11px;
                        font-weight: 400;
                        color: $light-grey;
                        border: none;
                        height: 16px;
                        margin-bottom: 4px;

                        &:not(.reversed) i {
                            margin-left: 4px;
                        }

                        &.reverse {
                            flex-direction: row-reverse;

                            i {
                                margin-right: 4px;
                            }
                        }

                        &:hover {
                            color: $blue;
                        }
                    }
                }

                .ui-metric-selector-tree-container {
                    right: 7px;
                    padding-top: 12px;
                    height: unset;
                    button.metric-expand {
                        margin-right: 0px;
                        top: 0px;
                    }
                }
            }
        }
    }

    .items-list {
        padding-left: 10px;
    }

    .main-part {
        display: flex;
        position: relative;
        flex-direction: row;
        justify-content: stretch;
        align-items: stretch;
        flex: 1 0 auto;
        z-index: 0;

        .main-body-part {
            justify-content: stretch;
            align-items: stretch;
            flex-direction: column;
            display: flex;
            flex: 1;
        }
    }

    &:not(.initializing) {
        opacity: 1;

        &:not(.notransition) {
            transition: transform 0.6s ease, opacity 0.3s ease, top 0.6s ease;
            .item-actions-panel {
                transition: opacity 0.6s ease;
                .group-by-select ul,
                .right {
                    transition: transform 0.7s ease;
                }
            }
            .button-toggle-actions-panel {
                transition: transform 0.6s ease, opacity 0.6s ease;
            }
            .button-export {
                transition: transform 0.6s ease;
                transition-delay: 0.2s;
            }
        }
    }

    &.initializing {
        visibility: hidden;
        opacity: 0;
    }

    &.hide-panel, &.hide-panel:not(.notransition) {
        .grid-bar .button-export {
            transition: transform 1s ease;
            transform: 0;
            transition-duration: 0.5s;
        }
        &:not(.experiments-sidebar) .grid-bar .button-export {
            transform: translateX(-$toggle-button-width);
        }
    }

    &.hide-panel {
        .item-actions-panel {
            opacity: 1;
        }
        .button-toggle-actions-panel {
            &.button-toggle-actions-panel-show {
                display: block;
                transition: transform 1s ease;
                transform: translateY(0);
                transition-duration: 0.7s;
            }
        }
        &:not(.notransition) {
            .button-toggle-actions-panel {
                &.button-toggle-actions-panel-show {
                    display: block;
                    transition-delay: 1s ease;
                    transition-duration: 0.7s;
                }
            }
        }
    }

    &:not(.notransition) > header {
        transition: height 0.5s ease-in-out;
    }

    & > header {
        border-top: none;
        box-shadow: 0px 1px 5px rgba(black, 0.05);
        background: $white;

        &.hidden {
            overflow: hidden;
        }
    }

    & > main {
        flex-grow: 1;
    }

    &.hide .button-toggle-actions-panel-show {
        display: block;
        &:hover {
            span,
            i {
                color: $blue;
            }
        }
    }

    .button-toggle-images {
        box-shadow: none;
        border: none;
        display: inline-block;
        vertical-align: middle;
        width: $toggle-button-width;
        opacity: 1;
        box-shadow: none;

        &:active,
        &:hover {
            background: transparent;
            box-shadow: none;
            span,
            i {
                color: $blue !important;
            }
        }

        span {
            margin-left: 5px;
        }

        span,
        i {
            font-size: 11px;
            font-weight: 400;
            color: $light-grey;
        }

        i {
            width: 10px;
            opacity: 0.5;
            position: relative;
            top: -1px;
        }
    }

    .button-toggle-actions-panel {
        box-shadow: none;
        border: none;
        display: inline-block;
        vertical-align: middle;
        width: $toggle-button-width;
        opacity: 1;

        &.button-toggle-actions-panel-show {
            display: none;
            position: absolute;
            right: 0;
            border-left: $border;
            border-bottom: $border;
            background: $white;
            height: 30px;
            line-height: 30px;
            transition: transform 1s ease-in-out;
            transform: translateY(-100%);
            span,
            i {
                font-size: 11px;
                font-weight: 400;
                color: $mid-grey;
            }
        }
        i {
            width: 10px;
            opacity: 0.5;
        }
    }

    .grid-bar {
        display: flex;
        flex-direction: row;
        position: relative;
        height: 43px;

        .button-export {
            flex: 0 0 auto;
            padding-right: 15px;
            user-select: none;
        }

        .item-natural-language-query {
            flex-grow: 1;
            font-size: 12px;
            font-style: italic;
            align-items: center;
            display: flex;
            margin-left: 10px;
            color: lighten($mid-grey, 3%);
            flex-wrap: wrap;

            > :not(.selected) {
                user-select: none;
            }

            > .selected {
                color: rgba($navy, 0.7);
                font-weight: 600;
                border-bottom: 1px dotted rgba($navy, 0.2);
                margin: 0px 5px;
            }
        }

        .button-export {
            right: 0;
            top: 0;
            height: $natural-language-query-height;
            line-height: $natural-language-query-height;
        }
    }

    .item-grid {
        top: $natural-language-query-height;
        height: 100%;
        width: 100%;
        border-top: $border;
        box-shadow: 0px -1px 3px rgba(black, 0.05);

        .grid-container,
        .grid {
            height: 100%;
            width: 100%;
        }

        .grid {
            .ag-header-viewport {
                margin-left: -1px !important;
                .ag-header-cell-text {
                    font-size: 12px;
                    &:before {
                        font-size: 10px;
                        content: '#';
                        color: rgba($light-grey, 0.75);
                        padding-right: 1px;
                    }
                }
            }

            .ag-cell-value {
                user-select: text;
            }

            .ag-center-cols-viewport {
                left: -1px;

                .ag-cell.ag-cell-value {
                    padding: 0;
                }
            }

            .ag-cell {
                font-weight: normal;
                padding: 0;
            }

            .ag-pinned-left-cols-viewport .ag-cell {
                pointer-events: none;
            }
        }
    }
}

@media (max-width: 800px) {
    .view-items-container .action-header .header-actions-panel {
        flex-direction: column;

        .group-by-select {
            border-bottom: $border;
            margin: 0px 0px 0px 0px;
            padding: 10px;
        }

        .right {
            padding-left: 10px;
        }
    }
}
